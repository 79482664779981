import type { PortalProps as MuiPortalProps } from '@mui/material';
import { Portal as MuiPortal } from '@mui/material';

export type PortalProps = MuiPortalProps;

/**
 *
 * API:
 *
 * - [Portal API](https://mui.com/base/react-portal/components-api/#portal)
 */
export const Portal = (props: MuiPortalProps) => <MuiPortal {...props} />;
