import config from '@packages/config';
import { cached } from '@packages/shared/src/utils/cached';
import { logger } from '@packages/utilities/src/logger/logger';
import type { DrupalApiPromotion } from '../../../../../interfaces';
import { fetcher } from '../../../../utils';
import type { Promotion } from '../../types';
import { mapDrupalPromotionToPromotion } from '../mapDataToPromotion';

/**
 * fetch a promotion by code
 *
 * @param code the code to be fetched by
 * @param locale the locale to be used
 * @param callOwnApi whether to call the own api or the bucket
 * @param testDate the test date to be used
 * @returns Promise<Promotion | undefined>
 * @throws Serverside error on fetching or network error
 */
export const fetchPromoData = async (
  code: { cart: string; texts: string },
  locale: string = 'de',
  callOwnApi?: boolean,
  testDate?: string,
): Promise<Promotion | undefined> => {
  // fetch from bucket only - the promotions are stored here after they are saved in drupal
  const fetchUrl = callOwnApi
    ? `/api/promotions/promotion/${code.texts}/?locale=${locale}`
    : config.promotionBanner.apiEndpoints.bucketPromotion
        .replace('<language>', locale.substring(0, 2))
        .replace('<code>', code.texts);
  return cached(
    [fetchUrl, code.cart, testDate].join(''),
    () =>
      fetcher<DrupalApiPromotion | Promotion>(fetchUrl)
        .then((fetchedData) => {
          if ('data' in fetchedData) {
            return (
              fetchedData && mapDrupalPromotionToPromotion(fetchedData.data[0], code.cart, testDate)
            );
          }
          return fetchedData;
        })
        .catch((error: any) => {
          // the TypeError represents a network error while the status code >= 500 is a server error
          if (error instanceof TypeError || error?.cause?.status >= 500) {
            logger.error('Error: Failed to fetch promotion data', error);
            throw error;
          }
          // all other errors are just warnings
          logger.warn('Temporarily failed to fetch promotion data', error);
          return undefined;
        }),
    5 * 60 * 1000,
  )();
};
