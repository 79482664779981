export const posContainerStyle = (alignment?: string) => {
  switch (alignment) {
    case 'left':
      return { left: 0 };
    case 'right':
      return { right: 0 };
    default:
      return { left: '50%' };
  }
};

export const posContainerVerticalStyle = (verticalAlignment?: string) => {
  switch (verticalAlignment) {
    case 'center':
    case 'stretch':
      return { top: '50%' };
    case 'flex-end':
    case 'bottom':
      return { bottom: 0 };
    default:
      return { top: 0 };
  }
};

export const transformStyle = (alignment?: string, verticalAlignment?: string) => {
  const horizontal = () => {
    switch (alignment) {
      case 'left':
      case 'right':
        return 'translateX(0)';
      default:
        return 'translateX(-50%)';
    }
  };
  const vertical = () => {
    switch (verticalAlignment) {
      case 'center':
      case 'stretch':
        return 'translateY(-50%)';
      default:
        return 'translateY(0)';
    }
  };
  return { transform: `${horizontal()} ${vertical()}` };
};
