import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgWarningCircle32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 33 32" sx={{
    ...sx
  }} {...other}><defs><circle id="warning-circle-32_svg__a" cx={16} cy={16} r={12} /><path id="warning-circle-32_svg__c" d="M3.5 9v3h-3V9zm0-9v6h-3V0z" /></defs><g fillRule="evenodd" transform="translate(.5)"><mask id="warning-circle-32_svg__b" fill="#fff"><use xlinkHref="#warning-circle-32_svg__a" /></mask><use xlinkHref="#warning-circle-32_svg__a" /><g mask="url(#warning-circle-32_svg__b)"><path d="M0 0h32v32H0z" /></g><g transform="translate(14 10)"><mask id="warning-circle-32_svg__d" fill="#fff"><use xlinkHref="#warning-circle-32_svg__c" /></mask><use xlinkHref="#warning-circle-32_svg__c" /><g fill="#FFF" mask="url(#warning-circle-32_svg__d)"><path d="M-4 0H8v12H-4z" /></g></g></g></SvgIcon>;
};
const Memo = memo(SvgWarningCircle32);
export default Memo;