import { useSearchParams } from 'next/navigation';
import { useMemo } from 'react';

import { useConfig, useCookies } from '@packages/utilities';
import { searchParamsToObject } from '@packages/shared';
import { useAbTest, useMouseMovementOutcome } from '@packages/abtesting';

import {
  getInspiringSearchSettingsInternal,
  getTestGroup,
  INSPIRING_SEARCH_TEST_ID,
} from './useInspiringSearchSettings.shared';

/**
 * Get the activation status for Inspiring Search features (client-side)
 */
export const useInspiringSearchSettings = () => {
  const config = useConfig();
  const searchParams = useSearchParams();
  const { getCookies } = useCookies();

  useMouseMovementOutcome(INSPIRING_SEARCH_TEST_ID);
  useAbTest(INSPIRING_SEARCH_TEST_ID);

  const testGroup = getTestGroup(getCookies());

  return useMemo(
    () => getInspiringSearchSettingsInternal(searchParamsToObject(searchParams), config, testGroup),
    [config, searchParams, testGroup],
  );
};
