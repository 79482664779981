import DOMPurify from 'isomorphic-dompurify';
import { useState } from 'react';
import reactStringReplace from 'react-string-replace';
import { Box, Dialog, Link } from '@packages/shared';
import { SoftHyphenInjector } from '../SoftHyphenInjector';
import type { ModalType } from '../Modal/types';

type ComponentProps = {
  /** replace placeholders within a string. Example: "Prices incl. VAT and plus [[TextLink]]." */
  replaceText: string;
  /** data object */
  data: ModalType;
};

export enum ModalByTextlinkTestIds {
  main = 'modal-by-textlink',
  mainChild = 'modal-by-textlink-child',
  content = 'modal-by-textlink-child-content',
  closeButton = 'close-iconbutton',
}

/** Composite dialog component with title and content that is initialized by the Component "ModalByTextlink". Based on Shared Dialog: @packages/shared/Dialog */
export const ModalChild = ({ replaceText, data }: ComponentProps) => {
  const {
    field_placeholder_id: placeholderId,
    field_placeholder_text: placeholderText,
    field_headline: headline = '',
    field_html_editor: modalContent = '',
  } = data;

  // init state
  const [stateOpen, setStateOpen] = useState<boolean>(false);

  // handle open/close
  const handleOpen = () => setStateOpen(true);
  const handleClose = () => setStateOpen(false);

  return (
    <div>
      {reactStringReplace(replaceText, placeholderId, () => (
        <span
          key={`${ModalByTextlinkTestIds.mainChild}-modal-child`}
          data-testid={ModalByTextlinkTestIds.mainChild}
        >
          <Link
            sx={{
              '&:link': {
                textDecoration: 'underline',
              },
            }}
            href="/"
            onClick={(event) => {
              event.preventDefault();
              handleOpen();
            }}
            data-testid={`${ModalByTextlinkTestIds.mainChild}-link`}
          >
            <SoftHyphenInjector text={placeholderText} />
          </Link>
          <Dialog
            open={stateOpen}
            title={headline || ''}
            handleClose={handleClose}
            dialogContent={
              <Box
                data-testid={ModalByTextlinkTestIds.content}
                sx={{ padding: '1rem', overflowY: 'auto', maxHeight: '85vh' }}
              >
                {/* eslint-disable react/no-danger */
                /* @ts-ignore */}
                <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(modalContent) }} />
              </Box>
            }
          />
        </span>
      )).map((chunk, index) =>
        // iterate over the returned array and render the chunks (that stayed a string) as html
        typeof chunk === 'string' ? (
          // eslint-disable-next-line react/no-array-index-key, react/no-danger
          <span key={index} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(chunk) }} />
        ) : (
          chunk
        ),
      )}
    </div>
  );
};
