import type { FC } from 'react';
import React from 'react';
import { usePathname } from 'next/navigation';

import { Box, Link, Image } from '@packages/shared';
import { ArrowMiniRight32 } from '@packages/themes/icons';
import type { GenericTrackingEvent } from '@packages/tracking';
import { useTracking } from '@packages/tracking';
import { useAbTesting } from '@packages/abtesting';

import type { NavItem } from '../types';
import { NAVIGATION_TEST_ID } from '../../useHasNewNavigation';

export const NavListItem: FC<{
  item: NavItem;
  setActiveItem: (item?: NavItem) => void;
  isActive?: boolean;
  showIcon?: boolean;
}> = ({
  setActiveItem,
  isActive = false,
  showIcon,
  item,
  item: { children, displayName = '', uri, icon },
}) => {
  const pathname = usePathname();
  const dispatchGTMEvent = useTracking();

  const isNotSubCat = uri ? uri.split('/').length < 3 : false;
  // NOTE: null check is for pages/app router compat mode, later for pure app router this can never be null
  const isSelected = children
    ? (pathname?.startsWith(uri || '') ?? false)
    : pathname === uri && isNotSubCat;

  const { setOutcome } = useAbTesting();

  return (
    <Link
      sx={{
        '&&': {
          color: 'text.dark',
          outline: 'none',
          position: 'relative',
          overflow: 'hidden',
          textDecoration: 'none',
          userSelect: 'none',
          alignItems: 'center',
          textOverflow: 'ellipsis',
          cursor: 'pointer',
          height: '2.8rem',
          fontSize: '1rem',
          paddingY: 0,
          paddingRight: 1,
          paddingLeft: showIcon ? 1 : 3,
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          borderRadius: 0,
          borderBottom: '1px solid rgba(0, 0, 0, 0.15)',

          ...(isActive
            ? {
                borderLeftColor: 'primary.main',
                boxShadow: '0 0 0 max(100vh, 100vw) rgba(0, 0, 0, 0.3)',
              }
            : {}),

          ...(isSelected
            ? {
                borderLeftColor: 'primary.main',
              }
            : {}),
        },

        'nav > &': {
          borderLeft: '0.25rem solid transparent',
          transition: '0.25s ease-in-out',
          transitionProperty: 'box-shadow border-color',
          willChange: 'box-shadow border-color',
          boxShadow: '0 0 0 max(100vh, 100vw) rgba(0, 0, 0, 0)',
          borderTopLeftRadius: '0.25rem',
          borderBottomLeftRadius: '0.25rem',
        },
      }}
      key={uri}
      data-testid="nav-item"
      onClick={(e: React.TouchEvent | React.MouseEvent) => {
        if (children) {
          e.preventDefault();
          setActiveItem(item);
          return;
        }
        dispatchGTMEvent<GenericTrackingEvent>({
          event: 'suche-event',
          eventValue: `navi=${encodeURIComponent(displayName)}`,
        });
        setOutcome(NAVIGATION_TEST_ID, { COF: 1 });
        setActiveItem(undefined);
      }}
      href={uri || ''}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {showIcon && (
          <Box
            sx={{
              mr: 1,
              width: '2rem',
              minWidth: '2rem',
              maxWidth: '2rem',
              height: '2rem',
              position: 'relative',
              zIndex: -1,
            }}
          >
            {icon && <Image fill src={icon} alt={displayName} title={displayName} loading="lazy" />}
          </Box>
        )}
        <Box
          sx={{
            flexGrow: 1,
            textAlign: 'left',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {displayName}
        </Box>
        {children && (
          <div>
            <ArrowMiniRight32 />
          </div>
        )}
      </Box>
    </Link>
  );
};
