import { useMemo } from 'react';
import { defineMessages } from 'react-intl';

const inspiredSearchPlaceholders = defineMessages({
  text1: { id: 'searchbar.animatedplaceholder.text1', defaultMessage: 'Geschenke für Mama' },
  text2: {
    id: 'searchbar.animatedplaceholder.text2',
    defaultMessage: 'Vorschläge für ein gemütliches Wohnzimmer',
  },
  text3: {
    id: 'searchbar.animatedplaceholder.text3',
    defaultMessage: 'Ideen für eine stilvoll dekorierte Küche',
  },
  text4: { id: 'searchbar.animatedplaceholder.text4', defaultMessage: 'Backzubehör' },
  text5: {
    id: 'searchbar.animatedplaceholder.text5',
    defaultMessage: 'Outfit-Ideen für den Sommerurlaub',
  },
  text6: {
    id: 'searchbar.animatedplaceholder.text6',
    defaultMessage: 'Ideen für praktische Küchenhelfer',
  },
  text7: {
    id: 'searchbar.animatedplaceholder.text7',
    defaultMessage: 'Geschenke für den Hobby-Koch',
  },
  text8: {
    id: 'searchbar.animatedplaceholder.text8',
    defaultMessage: 'Ideen für ein angenehmes Homeoffice',
  },
  text9: {
    id: 'searchbar.animatedplaceholder.text9',
    defaultMessage: 'Accessoires für das Badezimmer',
  },
  text10: {
    id: 'searchbar.animatedplaceholder.text10',
    defaultMessage: 'Dekoration für dein Wohnzimmer',
  },
  text11: {
    id: 'searchbar.animatedplaceholder.text11',
    defaultMessage: 'Wellness für Zuhause',
  },
  text12: {
    id: 'searchbar.animatedplaceholder.text12',
    defaultMessage: 'Passendes für deinen Yoga Kurs',
  },
  text13: {
    id: 'searchbar.animatedplaceholder.text13',
    defaultMessage: 'Geschenke für einen Grill-Fan',
  },
  text14: {
    id: 'searchbar.animatedplaceholder.text14',
    defaultMessage: 'Ideen für dein Smart Home',
  },
});

/** standard fisher-yates shuffle (https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle) */
const shuffled = <T>(array: T[]) => {
  const shuffledArray = [...array];

  // iterate from N-1 to 1
  for (let i = array.length - 1; i > 0; i -= 1) {
    // j should be a random integer such that 0 ≤ j ≤ i
    const j = Math.floor(Math.random() * (i + 1));

    const temp = array[i];
    shuffledArray[i] = array[j];
    shuffledArray[j] = temp;
  }

  return array;
};

export const useInspiringSearchPlaceholders = (maxCount: number = 5) =>
  useMemo(() => shuffled(Object.values(inspiredSearchPlaceholders)).slice(0, maxCount), [maxCount]);
