import type { ThemeOptions } from '@mui/material/styles';

// typography styles: https://mui.com/customization/typography/
// zeplin templates: https://app.zeplin.io/project/5d493736066e193158b2d910/screen/63762b618ee52e6d81bb42aa
export const createTypography: () => ThemeOptions['typography'] = () => ({
  fontFamily: 'Arial, "Helvetica Neue", Helvetica, sans-serif',
  body1: {
    fontSize: '0.938rem',
    lineHeight: '1.25rem',
  },
  body2: {
    fontSize: '0.813rem',
    lineHeight: '1.125rem',
  },
  body3: {
    fontSize: '0.688rem',
    lineHeight: '0.875rem',
  },
  h1: {
    fontSize: '2.625rem',
    lineHeight: '2.625rem',
  },
  h2: {
    fontSize: '1.75rem',
    lineHeight: '2rem',
  },
  h3: {
    fontSize: '1.5rem',
    lineHeight: '1.75rem',
  },
  h4: {
    fontSize: '1.313rem',
    lineHeight: '1.75rem',
  },
  h5: {
    fontSize: '1rem',
    lineHeight: '1.25rem',
  },
  h6: {
    fontSize: '0.813rem',
    lineHeight: '1.25rem',
  },
  caption: undefined,
  subtitle1: undefined,
  subtitle2: undefined,
  button: undefined,
  overline: undefined,
});
