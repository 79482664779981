import type { ComponentType } from 'react';
import type { SxProps } from '@mui/material';
import { Box, ErrorBoundary, Typography } from '@packages/shared';
import type { Suggestion, SuggestionCategoryResult } from '../types';
import { SuggestionItem } from './SuggestionItem';
import type { SuggestionItemProps } from './SuggestionItem';

interface SuggestionsSectionProps {
  /** Suggestions to show in section */
  suggestions: SuggestionCategoryResult;
  /** 
   * Component to display suggestion. Basic SuggestionItem to display only text or SuggestionArticleItem to display also article data.  
   @default SuggestionItem
   */
  SuggestComponent?: ComponentType<SuggestionItemProps>;
  /** MUI System sx prop */
  sx?: SxProps;
  /** Callback when item is clicked */
  onSuggestionItemClick?: (suggestion: Suggestion) => void;
}

/** Wrapper component for Suggestion Section (searchresults, categories, articles). Searchresults and categories show base text suggestion. Articles show thumbnail and price. */
export const SuggestionsSection = ({
  suggestions: { category, suggests },
  SuggestComponent = SuggestionItem,
  sx,
  onSuggestionItemClick,
}: SuggestionsSectionProps) => (
  <Box sx={sx}>
    <Typography sx={{ px: 2, py: 1, bgcolor: 'grey.main' }} variant="body2">
      {category.displayName}
    </Typography>
    {suggests.map((sr) => (
      <ErrorBoundary key={sr.value}>
        <SuggestComponent suggestion={sr} onClick={() => onSuggestionItemClick?.(sr)} />
      </ErrorBoundary>
    ))}
  </Box>
);
