import { useEffect, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Typography, Box } from '@packages/shared';
import { useSetAtom } from 'jotai';
import { useSearchFieldTracking } from '../useSearchFieldTracking';
import { useItemSelection } from '../useItemSelection';
import { InspiringSearchItem } from './InspiringSearchItem';
import { isInspiringSearchOnboardingOpenAtom } from '../../../Header.atoms';

interface InspiringSearchProps {
  /** Selected suggest keyboard item */
  cursor: number;
  /** Callback that is called when the user clicks the arrow button to accept a inspired search item as the current input value without immediately triggering search buddy */
  onAccept?: (value: string) => void;
  /** Update input value */
  setValue: (value: string, displayOnly?: boolean) => void;
}

const messages = defineMessages({
  inspiredSearchTitle: {
    id: 'inspiring.search.title',
    defaultMessage: 'Inspirierende Suche',
  },
  inspiredSearchLink: {
    id: 'inspiring.search.link',
    defaultMessage: 'Erfahre mehr zur inspirierenden Suche',
  },
});

const inspiringSuggestionSentences = defineMessages({
  suggestion1: {
    id: 'inspiringSearch.searchField.suggestion.1',
    defaultMessage: 'Geschenke für Mama',
  },
  suggestion2: {
    id: 'inspiringSearch.searchField.suggestion.2',
    defaultMessage: 'Wie schaffe ich Wellness Zuhause?',
  },
  suggestion3: {
    id: 'inspiringSearch.searchField.suggestion.3',
    defaultMessage: 'Vorschläge für ein gemütliches Wohnzimmer',
  },
  suggestion4: {
    id: 'inspiringSearch.searchField.suggestion.4',
    defaultMessage: 'Ideen für eine stilvoll dekorierte Küche',
  },
  suggestion5: {
    id: 'inspiringSearch.searchField.suggestion.5',
    defaultMessage: 'Was brauche ich zum Backen?',
  },
  suggestion6: {
    id: 'inspiringSearch.searchField.suggestion.6',
    defaultMessage: 'Outfit Ideen für den Sommerurlaub',
  },
  suggestion7: {
    id: 'inspiringSearch.searchField.suggestion.7',
    defaultMessage: 'Ideen für praktische Küchenhelfer',
  },
  suggestion8: {
    id: 'inspiringSearch.searchField.suggestion.8',
    defaultMessage: 'Geschenke für den Hobby-Koch',
  },
  suggestion9: {
    id: 'inspiringSearch.searchField.suggestion.9',
    defaultMessage: 'Ideen für ein angenehmes Homeoffice',
  },
  suggestion10: {
    id: 'inspiringSearch.searchField.suggestion.10',
    defaultMessage: 'Accessoires für mein Badezimmer',
  },
  suggestion11: {
    id: 'inspiringSearch.searchField.suggestion.11',
    defaultMessage: 'Dekoration für mein Wohnzimmer',
  },
  suggestion12: {
    id: 'inspiringSearch.searchField.suggestion.12',
    defaultMessage: 'Ideen für ein Smart Home',
  },
  suggestion13: {
    id: 'inspiringSearch.searchField.suggestion.13',
    defaultMessage: 'Inspiration für meinen Yoga Kurs',
  },
  suggestion14: {
    id: 'inspiringSearch.searchField.suggestion.14',
    defaultMessage: 'Wie kann ich mein Zuhause sicherer machen?',
  },
  suggestion15: {
    id: 'inspiringSearch.searchField.suggestion.15',
    defaultMessage: 'Geschenke für einen Grill-Fan',
  },
});

const shuffleInspiringSuggestions = <T,>(array: T[]) => {
  const shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

export const InspiringSearch = ({ onAccept, setValue, cursor }: InspiringSearchProps) => {
  const setIsOnboardingModalOpen = useSetAtom(isInspiringSearchOnboardingOpenAtom);

  const { formatMessage } = useIntl();

  const { dispatchSearchGtmEvent } = useSearchFieldTracking();

  const randomSuggestions = useMemo(
    () =>
      shuffleInspiringSuggestions(Object.values(inspiringSuggestionSentences))
        .slice(0, 3)
        .map((message) => formatMessage(message)),
    [formatMessage],
  );

  const [selectedItem, selectedIndex] = useItemSelection(cursor, randomSuggestions);

  useEffect(() => {
    setValue(selectedItem ?? '', true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  return (
    <>
      <Box component="li" sx={{ p: 1, backgroundColor: 'grey.main' }}>
        <Typography sx={{ fontWeight: 600, mt: 0.5, mr: 4, mb: 0.75, ml: 0.75 }}>
          {formatMessage(messages.inspiredSearchTitle)}
        </Typography>
      </Box>
      <Box
        component="ul"
        sx={{
          backgroundImage: 'url("/search-assets/inspiring-search-background.svg")',
          backgroundSize: '100% 100%',
        }}
      >
        {randomSuggestions.map((entry, i) => (
          <InspiringSearchItem
            selected={selectedIndex === i}
            onClick={() => dispatchSearchGtmEvent(entry)}
            onArrowClick={() => onAccept?.(entry)}
            url={`/s/${entry}/`}
            key={entry}
            label={entry}
          />
        ))}
        <InspiringSearchItem
          testId="inspiring search information"
          selected={selectedIndex === randomSuggestions.length}
          label={formatMessage(messages.inspiredSearchLink)}
          withAdoptButton={false}
          onClick={() => setIsOnboardingModalOpen(true)}
          sx={{ textDecoration: 'underline', ':hover': { textDecoration: 'underline' } }}
        />
      </Box>
    </>
  );
};
