import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgBasket32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M28.5 7.6c-.3-.3-.7-.5-1.2-.5H10.5v1.1h16.8c.2 0 .3 0 .3.1s.1.2 0 .4c-.2.8-2.4 7.7-2.6 8.3-.1.3-.1.3-.5.3H11.9c-1-3.4-2.6-8.7-3.6-12l-.1-.4H3.3V6h4.1c.7 2.4 4.4 14.7 4.5 15 .2.6.7 1.2 1.5 1.2h11.4v-1.1H13.4c-.2 0-.4-.3-.4-.4 0-.1-.3-.9-.7-2.2h12.2c1 0 1.4-.6 1.5-1.1.1-.4 2.4-7.5 2.6-8.3.3-.8.1-1.2-.1-1.5M12.7 26c-.6 0-1.1-.5-1.1-1.1s.5-1.1 1.1-1.1c.6 0 1.1.5 1.1 1.1s-.5 1.1-1.1 1.1m0-3.3c-1.2 0-2.2 1-2.2 2.2 0 1.2 1 2.2 2.2 2.2s2.2-1 2.2-2.2c0-1.2-1-2.2-2.2-2.2M23.4 26c-.6 0-1.1-.5-1.1-1.1s.5-1.1 1.1-1.1 1.1.5 1.1 1.1S24 26 23.4 26m0-3.3c-1.2 0-2.2 1-2.2 2.2 0 1.2 1 2.2 2.2 2.2 1.2 0 2.2-1 2.2-2.2 0-1.2-1-2.2-2.2-2.2" /></SvgIcon>;
};
const Memo = memo(SvgBasket32);
export default Memo;