/* istanbul ignore file */
import type {
  AnyVariables,
  DocumentInput,
  OperationContext,
  UseMutationExecute,
  UseMutationResponse,
  UseQueryArgs,
} from 'urql';
import {
  createClient,
  fetchExchange,
  ssrExchange,
  Provider,
  cacheExchange as documentCacheExchange,
  useQuery,
  useMutation,
} from 'urql';
import { cacheExchange } from '@urql/exchange-graphcache';
import type { ReactNode } from 'react';
import { useEffect, useMemo, useRef } from 'react';
import { useSession } from '@packages/utilities';
import { reviewsPagination } from './reviewsPagination';

export const createGqlApiUrl = (isServer: boolean = false) =>
  // GQL_GATEWAY is set as env variable in the deploymment values to enable direct routing with the local pod url on serverside
  process.env.GQL_GATEWAY || `${isServer ? process.env.SERVER_HOST : ''}/api/gateway/`;

/** *************** BEGIN TOKEN HANDLING **************** */
/** We decided not to use urql auth exchange because actually SessionProvider does all the magic */
/** instead we inject our token by using this two wrapper hooks */
/** we also prevent possible multi renderings based on async token generation */

// improve possibility for passing options if needed
export const useAuthQuery = <Data = any, Variables extends AnyVariables = AnyVariables>({
  query,
  variables,
  requestPolicy,
  pause,
}: UseQueryArgs<Variables, Data>) => {
  const { jwtToken } = useSession();
  return useQuery({
    query,
    variables,
    requestPolicy: requestPolicy || 'cache-first',
    context: useMemo(
      () => ({ suspense: false, fetchOptions: { headers: { 'x-ec-token': jwtToken! } } }),
      [jwtToken],
    ),
    pause: !jwtToken || pause,
  });
};

// improve possibility for passing options if needed
export const useAuthMutation = <Data = any, Variables extends AnyVariables = AnyVariables>(
  query: DocumentInput<Data, Variables>,
): UseMutationResponse<Data, Variables> => {
  const { jwtToken } = useSession();
  const [state, executable] = useMutation(query);
  const authExecutable: UseMutationExecute<Data, Variables> = useMemo(
    () => (variables: Variables, context?: Partial<OperationContext>) => {
      const authContext = {
        ...context,
        fetchOptions: {
          headers: { 'x-ec-token': jwtToken || '' }, // TODO what if no token??
        },
      } as unknown as OperationContext;
      if (process.env.STORYBOOK && authContext.fetchOptions) {
        // this applies only for storybook to enable msw mocking
        // see https://github.com/mswjs/msw/issues/1593
        (authContext.fetchOptions as { headers: { accept: string } }).headers.accept = '*/*';
      }
      return executable(variables!, authContext);
    },
    [executable, jwtToken],
  );

  return [state, authExecutable];
};
/** *************** END TOKEN HANDLING **************** */

export function createUrqlClient(
  initialState?: NonNullable<Parameters<typeof ssrExchange>[0]>['initialState'],
) {
  const ssrExCache = ssrExchange({
    isClient: typeof window !== 'undefined',
    initialState,
  });

  return {
    ssrExCache,
    client: createClient({
      url: createGqlApiUrl(),
      exchanges: [
        cacheExchange({
          // TODO: add schema awareness after merging endpoints and the introspection is correct
          // schema: schema as CacheExchangeOpts['schema'],
          keys: {
            AbsoluteDiscount: () => null,
            AbTesting: () => null,
            AdditionalFlag: () => null,
            AlternateLocalizedLink: () => null,
            APlus: () => null,
            AttributeValue: () => null,
            Availability: () => null,
            AvailabilityDescription: () => null,
            AvailableVariations: () => null,
            Bonuspoints: () => null,
            Basket: () => 'MyUniqueBasketId',
            BasketAPlus: () => null,
            BasketAvailability: () => null,
            BasketPowerEfficiencyFlag: () => null,
            BasketPriceSaving: () => null,
            BasketProduct: () => null,
            BasketProductPrice: () => null,
            BasketService: () => null,
            BasketServiceCluster: () => null,
            Brand: () => null,
            BrandRecommendation: () => null,
            Breadcrumb: () => null,
            BreadcrumbData: () => null,
            BreadcrumbItem: () => null,
            BreadcrumbSchema: () => null,
            CategoryRecommendation: () => null,
            CategoryQueryResult: () => null,
            CategoryResult: () => null,
            CliplisterData: () => null,
            Cluster: () => null,
            CompleteVariationGroup: () => null,
            Countdown: () => null,
            CriteoPlacementBeacon: () => null,
            CriteoProductsResponse: () => null,
            CustomFlag: () => null,
            CustomerInformation: () => null,
            DefaultFilterSelection: () => null,
            Delivery: () => null,
            DeliveryData: () => null,
            Download: () => null,
            EcoLabel: () => null,
            EmptySearchResult: () => null,
            ExpressServiceData: () => null,
            FlatCategoryItem: () => null,
            GroupedFilterValue: () => null,
            Hint: () => null,
            InstallmentData: () => null,
            InstallmentValue: () => null,
            ItemListElement: () => null,
            LineItemExtraData: () => null,
            LoadbeeData: () => null,
            LoyaltyProgram: () => null,
            MediaAsset: () => null,
            NewTestAssignment: () => null,
            OocvRow: () => null,
            OocvTable: () => null,
            Offer: () => null,
            PercentDiscount: () => null,
            PowerEfficiencyFlag: () => null,
            PreviewIcon: () => null,
            PriceSaving: () => null,
            Product: (data) => data.sku as string,
            ProductAttributes: () => null,
            ProductAttributeType: () => null,
            ProductDimension: () => null,
            ProductDimensionRange: () => null,
            ProductDimensionValue: () => null,
            ProductPrice: () => null,
            ProductRecommendation: () => null,
            ProductResponse: () => null,
            ProductVariation: () => null,
            ProperCategoryQueryResult: () => null,
            PrudsysCategoryResponse: () => null,
            PrudsysCategoryToCategories: () => null,
            PrudsysProductResponse: () => null,
            RatingProduct: () => null,
            RatingResult: () => null,
            RatingReview: () => null,
            RatingReviewer: () => null,
            Ratings: () => null,
            RatingSummaryValues: () => null,
            RatingTag: () => null,
            RatingTags: () => null,
            RecipientInformationResponse: () => null,
            RecoBrand: () => null,
            RecoBrandResponse: () => null,
            RecoBrandTracking: () => null,
            RecoCategory: () => null,
            RecoCategoryTopsellerProduct: () => null,
            RecoCategoryTracking: () => null,
            RecoProductTracking: () => null,
            RecoProductTrackingUrl: () => null,
            ReviewStatistic: () => null,
            ReviewTag: () => null,
            ReviewTags: () => null,
            SearchDefaultFilter: () => null,
            SearchProductPrice: () => null,
            SearchProductResult: () => null,
            SearchQueryResult: () => null,
            SearchResultBase: () => null,
            SearchRedirectResult: () => null,
            SearchResultDiagnostics: () => null,
            SearchResultSummary: () => null,
            SearchRangeFilter: () => null,
            SearchTrackingInformation: () => null,
            SeoData: (data) => data.sku as string,
            SeoDataV2: () => null,
            SeoLink: () => null,
            SeoLinkV2: () => null,
            Service: () => null,
            SimpleProductPrice: () => null,
            SortingOrder: (data) => data.key as string,
            SponsoredProductData: () => null,
            SponsoredProductsTracking: () => null,
            SponsoredStylePreviewItem: () => null,
            StandardFlag: () => null,
            StyleBrand: () => null,
            StylePreviewItem: () => null,
            SuggestionPreview: () => null,
            SuggestionPreviewSearchResult: () => null,
            TireEfficiency: () => null,
            TireEfficiencyFlag: () => null,
            TopCategory: () => null,
            UnitPackage: () => null,
            Wishlist: () => 'MyUniqueWishlistId',
            WishlistLineItem: () => null,
          },
          resolvers: {
            Query: {
              rating: reviewsPagination(),
            },
          },
        }),
        ssrExCache,
        fetchExchange,
      ],
      suspense: true,
    }),
  };
}

export const createUrqlServerClient = () => {
  const ssrCache = ssrExchange({
    isClient: false,
    initialState: {},
  });

  return {
    ssrCache,
    ssrClient: createClient({
      url: createGqlApiUrl(true),
      exchanges: [documentCacheExchange, ssrCache, fetchExchange],
      suspense: false,
      requestPolicy: 'network-only',
    }),
  };
};

export const UrqlProvider = ({ children, pageProps }: { children: ReactNode; pageProps: any }) => {
  const initialRender = useRef(true);

  const { ssrExCache, client: urqlClient } = useMemo(() => {
    const client = createUrqlClient(pageProps.urqlState);
    // Serialize the urqlClient to null on the client-side.
    // This ensures we don't share client and server instances of the urqlClient.
    (client.client as any).toJSON = () => null;
    return client;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // restore server fetched data on client page transition
  if (pageProps.urqlState && !initialRender.current) {
    ssrExCache.restoreData(pageProps.urqlState);
  }

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    }
  }, []);

  return <Provider value={urqlClient}>{children}</Provider>;
};
