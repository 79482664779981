/* eslint-disable @typescript-eslint/naming-convention, @next/next/no-img-element */
import { Box, Tooltip } from '@packages/shared';
import { useGetTooltipContent } from '../../hooks/useGetTooltipContent';
import { CmsImageItem } from './CmsImageItem';
import { registerComponents } from '../ComponentRecognizer/componentRegistry';
import { ComponentTypes } from '../../../interfaces';

import type { CmsImageType } from './types';

type CmsImageProps = {
  data: CmsImageType;
};

// Helper function to convert alignment string to its corresponding CSS flex property value
const styleJustifyContent = (Align: string) => {
  switch (Align) {
    case 'left':
      return 'flex-start';
    case 'center':
      return 'center';
    case 'right':
      return 'flex-end';
    default:
      return 'unset';
  }
};

/**
 * CmsImage component to render image content fetched from the CMS.
 *
 * @param data containing image data and optional fields.
 * @returns JSX.Element A Box component containing the i mage or a linked image.
 */
export const CmsImage = ({ data }: CmsImageProps) => {
  const {
    field_margin: fieldMargin = '0',
    field_distance: distanceType,
    field_alignment: Align = 'left',
    field_custom_id: dynamicYieldId,
    promotion_item: tooltipDataPromotion,
    content_snippet_bundled: tooltipDataContentSnippet,
    behavior_paragraph_tooltip_activation: tooltipActivation,
    behavior_paragraph_tooltip_positioning: tooltipPositioning,
  } = data;

  const tooltipContent = useGetTooltipContent(tooltipDataPromotion, tooltipDataContentSnippet);

  return (
    <Box
      className="image"
      data-dy={dynamicYieldId}
      component="span"
      sx={{
        display: 'flex',
        ...(Align && {
          justifyContent: styleJustifyContent(Align),
        }),
        ...(distanceType === 'variation-1' && {
          paddingInline: { xs: 3, lg: 0 },
        }),
      }}
    >
      <Box
        component="span"
        sx={{
          display: 'flex',
          position: 'relative',
          width: '100%',
          ...(fieldMargin !== '' && {
            margin: fieldMargin,
          }),
        }}
      >
        {tooltipContent && !!tooltipActivation ? (
          <Tooltip content={tooltipContent} positioning={tooltipPositioning}>
            <CmsImageItem data={data} />
          </Tooltip>
        ) : (
          <CmsImageItem data={data} />
        )}
      </Box>
    </Box>
  );
};

// Needed to run this component in Storybook and Jest tests. Register the component in a global componentRegistry object
registerComponents({
  [ComponentTypes.SvgFile]: CmsImage,
  [ComponentTypes.ImageSimple]: CmsImage,
  [ComponentTypes.ImageSimpleParagraph]: CmsImage,
});
