import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import dayjs from 'dayjs';

/**
 * Writes scrid query parameter to localStorage if present
 */
export const usePersistentScrid = () => {
  const searchParams = useSearchParams();
  const scrid = searchParams?.get('scrid');

  useEffect(() => {
    if (scrid) {
      const createdAt = dayjs();
      localStorage.setItem(
        'scrid',
        JSON.stringify({
          createdAt,
          expiresAt: createdAt.add(6, 'hours').toDate(),
          value: scrid.toString(),
        }),
      );
    }
  }, [scrid]);
};
