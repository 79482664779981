import { useRef, type FC, useEffect, useState, Fragment } from 'react';
import { Card, CardMedia, CardContent, CardActionArea, Stack, Divider, Box } from '@mui/material';
import {
  Typography,
  Flag,
  EfficiencyFlag,
  ProductPrice,
  ProductColors,
  getImageSize,
  Image,
  Link,
  RatingDisplay,
  useDeviceType,
  useI18n,
} from '@packages/shared';
import {
  useTracking,
  type GTMEventGlycerinProductClick,
  type GlycerinProductClickPayload,
  type GTMEventGlycerinAdClick,
  type GlycerinAdClickPayload,
  useIsBot,
} from '@packages/tracking';
import { getCanonicalUrl } from '@packages/utilities';
import { useRouter } from 'next/navigation';
import { WishlistButtonWithApi } from '../WishlistButtonWithApi';
import { Title } from './Title';
import type { ProductCardProps, ProductCardFlag } from './types';
import { ProductAvailabilityOverlay } from './ProductAvailabilityOverlay';

// Get image file name without extension from absolute URL
export const getImageFileName = (path: string): string => {
  const fileNameWithExtension = path.substring(path.lastIndexOf('/') + 1); // "file.txt"
  const fileName = fileNameWithExtension.split('.').slice(0, -1).join('.'); // "file"
  return fileName;
};

const getSortedFilteredFlags = ({
  minimal,
  flags,
}: {
  minimal: boolean;
  flags: ProductCardFlag[];
}) => {
  // sort flags by type
  const sortedFlags = flags.sort((a: ProductCardFlag, b: ProductCardFlag) => {
    const order = ['custom', 'sale', 'neutral', 'secondary', 'sustainability'];
    return order.indexOf(a.type || '') - order.indexOf(b.type || '');
  });

  const filteredFlags = sortedFlags.filter((flag: ProductCardFlag) => {
    // minimal product card has no custom flag
    if (minimal) {
      return flag.type !== 'custom';
    }
    // normal product card has no sale flag
    return flag.type !== 'sale';
  });

  // minimal product card has only 2 flags, otherwise 3
  return minimal ? filteredFlags.slice(0, 2) : filteredFlags.slice(0, 3);
};

const MIN_WIDTH_FOR_THREE_COLORS = 184;

/**
 * ProductCard component based on https://mui.com/material-ui/react-card/
 * */
export const ProductCard: FC<ProductCardProps> = ({
  availability,
  variationName,
  sku,
  productLink = '#',
  image,
  brand,
  title,
  price,
  colors,
  minimal = false,
  flags,
  efficiencyFlags,
  datasheets,
  additionalInfo,
  priority,
  rating,
  isSponsored,
  sx,
  onColorChange,
  onProductLinkActionAreaClick,
  shrinkToFit = false,
  useAdditionalFlagsSpacer,
  useRatingsSpacer,
  clickTrackingProps,
  wishListTrackingProps,
  ...rest
}) => {
  const { isDesktop } = useDeviceType();
  const [visibleColorsCount, setVisibleColorsCount] = useState(3);
  const cardRef = useRef<HTMLDivElement>(null);
  const dispatchGTMEvent = useTracking();
  const { localizeUrl, language } = useI18n();

  const { push } = useRouter();

  const px = minimal ? [0.5, null, 1] : 'auto';

  const hasAvailabilityInfo =
    availability && (availability.alternateText || availability.values?.length > 0);

  useEffect(() => {
    const element = cardRef?.current;

    if (!element) return;

    const observer = new ResizeObserver(() => {
      setVisibleColorsCount(element.clientWidth >= MIN_WIDTH_FOR_THREE_COLORS ? 3 : 2);
    });

    observer.observe(element);
    // eslint-disable-next-line consistent-return
    return () => {
      // Cleanup the observer by unobserving all elements
      observer.disconnect();
    };
  }, []);

  const handleActionAreaClick = () => {
    if (isSponsored) {
      // Sponsored Products click needs to be an adClick event
      dispatchGTMEvent<GTMEventGlycerinAdClick>({
        event: 'AdClick',
        AdClickData: {
          category: clickTrackingProps?.category ?? 'sponsored-product-click',
          adId: (clickTrackingProps as GlycerinAdClickPayload)?.adId,
          adType: (clickTrackingProps as GlycerinAdClickPayload)?.adType ?? 'sponsored product',
          target: (clickTrackingProps as GlycerinAdClickPayload)?.target ?? productLink,
          placement: clickTrackingProps?.placement,
          detail: clickTrackingProps?.detail,
          label: clickTrackingProps?.label,
          listPosition: clickTrackingProps?.listPosition,
          custom: clickTrackingProps?.custom,
          variantIds: clickTrackingProps?.variantIds,
        },
      });
    } else {
      // "regular" product click
      dispatchGTMEvent<GTMEventGlycerinProductClick>({
        event: 'ProductClick',
        ProductClickData: {
          category: clickTrackingProps?.category ?? 'product-click',
          productId: (clickTrackingProps as GlycerinProductClickPayload)?.productId,
          placement: clickTrackingProps?.placement,
          articleId: (clickTrackingProps as GlycerinProductClickPayload)?.articleId,
          detail: clickTrackingProps?.detail,
          imageType: (clickTrackingProps as GlycerinProductClickPayload)?.imageType,
          label: clickTrackingProps?.label,
          listId: (clickTrackingProps as GlycerinProductClickPayload)?.listId,
          listPosition: (clickTrackingProps as GlycerinProductClickPayload)?.listPosition,
          origin: (clickTrackingProps as GlycerinProductClickPayload)?.origin,
          recoId: (clickTrackingProps as GlycerinProductClickPayload)?.recoId,
          skuId:
            availability?.values.length === 1
              ? (clickTrackingProps as GlycerinProductClickPayload)?.skuId
              : undefined,
          custom: clickTrackingProps?.custom,
          variantIds: clickTrackingProps?.variantIds,
        },
      });
    }
    if (onProductLinkActionAreaClick) {
      onProductLinkActionAreaClick();
    }
  };

  let linkTarget = productLink;
  const isBot = useIsBot();
  if (isBot) {
    linkTarget = getCanonicalUrl(productLink);
  }

  const imageSize = getImageSize(image) ?? { width: 339, height: 485 };

  return (
    <Card
      {...rest}
      ref={cardRef}
      title={[brand, title].join(' ')}
      sx={{
        ...sx,
        display: 'flex',
        position: 'relative',
        flexDirection: additionalInfo ? 'row' : 'column',
        height: '100%',
        '.availability-overlay': {
          opacity: 0,
        },
        '&:hover': {
          '.availability-overlay': {
            opacity: 1,
          },
        },
      }}
    >
      <Box sx={{ position: 'relative', width: additionalInfo ? '50%' : '100%' }}>
        {!isSponsored && (
          <WishlistButtonWithApi
            sku={sku}
            size={minimal ? 'medium' : 'large'}
            sx={{
              position: 'absolute',
              right: 4,
              top: 4,
              zIndex: 1000,
              '.MuiButton-startIcon > *:nth-of-type(1)': {
                fontSize: 32,
              },
            }}
            availability="UNSELECTED"
            trackingProps={
              wishListTrackingProps
                ? {
                    products: [
                      {
                        // TODO: availability is currently pretty hard to map to the wishlist types, try to add it with or after the GraphQL migration
                        // availability: availability?.values?.[0]?.availabilityName,
                        currency:
                          price.currency === 'EUR' || price.currency === 'CHF'
                            ? price.currency
                            : 'EUR',
                        displayedPrice: price.price,
                        originalPrice: price.oldPrice,
                        ...wishListTrackingProps.product,
                      },
                    ],
                  }
                : undefined
            }
          />
        )}
        <CardActionArea
          onClick={handleActionAreaClick}
          href={linkTarget}
          sx={{ zIndex: additionalInfo ? 2 : 1 }}
          LinkComponent={Link}
        >
          {flags && (
            <Stack
              spacing={0.5}
              sx={{
                position: 'absolute',
                left: 0,
                top: 0,
                zIndex: 1,
                m: 1,
                alignItems: 'flex-start',
              }}
            >
              {getSortedFilteredFlags({ flags, minimal }).map((flag) =>
                flag.type === 'custom' ? (
                  // flag with custom image
                  <Image
                    width="68"
                    height="28"
                    style={{
                      height: 'max-content',
                      maxHeight: 28,
                      objectFit: 'contain',
                      objectPosition: 'left',
                    }}
                    src={flag.src}
                    alt={getImageFileName(flag.src)}
                    key={flag.src}
                  />
                ) : (
                  <Flag text={flag.text} type={flag.type} key={flag.text} />
                ),
              )}
            </Stack>
          )}
          <CardMedia
            component="div"
            sx={{
              position: 'relative',
              height: shrinkToFit ? 'auto' : imageSize.height,
              maxHeight: imageSize.height,
              display: 'flex',
              alignItems: 'center',
              '> img': {
                width: '100%',
                height: 'auto',
                objectFit: 'contain',
                maxWidth: '100%',
                maxHeight: '100%',
              },
              ...(minimal && { maxHeight: 200 }),
              ...(additionalInfo && { maxHeight: 400, maxWidth: 'calc(100% - 1px)' }),
            }}
          >
            <Image
              src={image}
              alt={title}
              width={imageSize.width}
              height={imageSize.height}
              priority={priority}
            />
          </CardMedia>
          {isDesktop && hasAvailabilityInfo && variationName && (
            <ProductAvailabilityOverlay
              onProductLinkClick={onProductLinkActionAreaClick}
              availability={availability}
              variationName={variationName}
              alternateLink={linkTarget}
              className="availability-overlay"
            />
          )}
        </CardActionArea>
        {additionalInfo && <Divider orientation="vertical" absolute sx={{ zIndex: 1 }} />}
      </Box>
      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          flexDirection: 'column',
          flexGrow: 1,
          width: additionalInfo ? '50%' : '100%',
        }}
      >
        <CardActionArea
          onClick={handleActionAreaClick}
          href={linkTarget}
          sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
          LinkComponent={Link}
        >
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              flexGrow: 1,
              px,
              py: minimal ? 1 : 'auto',
              ...(colors && !isSponsored && { pb: 0.5 }),
            }}
          >
            <Typography color="text.secondary" variant="body2" mb={1}>
              {brand}
            </Typography>
            <Title minimal={minimal}>{title}</Title>
            <ProductPrice {...price} hasDiscountFlag={!minimal} />
          </CardContent>
        </CardActionArea>

        {colors && !minimal && !isSponsored && (
          <CardContent sx={{ pt: 0.5 }}>
            <ProductColors
              colorsVisible={visibleColorsCount}
              colors={colors}
              onColorChange={(index: number) => {
                if (typeof onColorChange === 'function') {
                  onColorChange(index);
                }
                handleActionAreaClick();
              }}
              onMoreLabelClick={() => push(localizeUrl(linkTarget, language))}
            />
          </CardContent>
        )}

        {additionalInfo && (
          <CardActionArea
            onClick={handleActionAreaClick}
            href={linkTarget}
            sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
            LinkComponent={Link}
          >
            <CardContent sx={{ pt: 0.5, px, py: minimal ? 1 : 'auto' }}>
              <Typography component="ul" variant="body2" sx={{ pl: 2, listStyleType: 'disc' }}>
                {additionalInfo.map((data, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={`${data}-${index}`}>{data}</li>
                ))}
              </Typography>
            </CardContent>
          </CardActionArea>
        )}

        {rating?.avgRating && (
          <CardContent sx={{ pt: 0 }}>
            <RatingDisplay avgRating={rating.avgRating} reviewCount={rating.reviewCount} />
          </CardContent>
        )}

        {useRatingsSpacer && !rating?.avgRating && (
          <CardActionArea
            onClick={handleActionAreaClick}
            href={linkTarget}
            sx={{ padding: 0 }}
            LinkComponent={Link}
          >
            <Box
              sx={{
                height: '40px',
              }}
            />
          </CardActionArea>
        )}

        {efficiencyFlags && (
          <>
            <Divider />
            {efficiencyFlags.some((x) => x.datasheet) ? (
              <CardContent
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'auto 1fr',
                  gap: 1,
                  px,
                  py: 1,
                }}
              >
                {efficiencyFlags.map((flagProps, index) => (
                  <Fragment key={flagProps.overlay?.title ?? index}>
                    <EfficiencyFlag {...flagProps} />
                    <Stack sx={{ justifyContent: 'center' }}>
                      {efficiencyFlags.length > 1 && flagProps.overlay && (
                        <Typography variant="body3" fontWeight="600">
                          {flagProps.overlay.title}
                        </Typography>
                      )}
                      {flagProps.datasheet && (
                        <Link
                          href={flagProps.datasheet.url}
                          target="_blank"
                          // NOTE: currently there is no canonical way of having monochrome links, they default to primary (both text and decoration)
                          sx={(theme) => ({
                            color: theme.palette.text.dark,
                            textDecorationColor: theme.palette.text.dark,
                            '&:hover': {
                              color: theme.palette.grey.dark,
                              textDecorationColor: theme.palette.grey.dark,
                            },
                          })}
                        >
                          <Typography variant="body3">{flagProps.datasheet.name}</Typography>
                        </Link>
                      )}
                    </Stack>
                  </Fragment>
                ))}
              </CardContent>
            ) : (
              <CardContent
                sx={{
                  display: 'flex',
                  flexFlow: 'row wrap',
                  gap: 1,
                  px,
                  py: 1,
                }}
              >
                {efficiencyFlags.map((flagProps, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <EfficiencyFlag {...flagProps} key={index} />
                ))}
              </CardContent>
            )}
            {datasheets?.map((datasheet) => (
              <CardActionArea href={datasheet.url} target="_blank">
                <CardContent
                  sx={{
                    color: 'text.dark',
                    typography: 'body2',
                    textDecoration: 'underline',
                    px,
                    py: 1,
                  }}
                >
                  {datasheet.name}
                </CardContent>
              </CardActionArea>
            ))}
          </>
        )}
        {useAdditionalFlagsSpacer && !efficiencyFlags && (
          <CardActionArea
            onClick={handleActionAreaClick}
            href={linkTarget}
            sx={{ padding: 0 }}
            LinkComponent={Link}
          >
            <Box
              sx={{
                height: datasheets?.length ? '71px' : '53px', // +28px for wrapping flags
              }}
            />
          </CardActionArea>
        )}
      </Box>
    </Card>
  );
};
