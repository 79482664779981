'use client';

import { type FC } from 'react';
import { SWRConfig } from 'swr';

import { ErrorBoundary, useDeviceType, useUserAgentDevice } from '@packages/shared';
import { useConfig } from '@packages/utilities';

import { InspiringSearchOnboardingModal } from '../../InspiringSearch/InspiringSearchOnboardingModal';
import { AppBanner } from '../AppBanner';
import type { HeaderProps } from '../Header';
import { DesktopNavigation, MobileNavigation, ResponsiveNavigationLoader } from '../Navigation';
import { ResponsiveHeaderLayout } from '../ResponsiveHeaderLayout';
import { LinkedLogo } from '../Toolbar/LinkedLogo';
import { DynamicSearchField } from '../Toolbar/SearchField';
import { ArrowBox, SlotsDefault } from '../Toolbar/Slots';
import { headerDefaultFetcher } from '../api';
import { useHasNewNavigation } from '../useHasNewNavigation';

const SEARCH_ICON_MOBILE_RIGHT_POSITION = '123px';

/**
 * Integrated header module with appbanner (only mobile), toolbar and navigation.
 * This component is able to get its data whether from client or it accepts the SEO relevant initial data from server to populate SWR cache and to make its further fetches and revalidations on client.
 */
export const DefaultHeader: FC<HeaderProps> = () => {
  const {
    header: { desktopNavigationMode },
  } = useConfig();
  // NOTE both are false during SSR
  const userAgentDevice = useUserAgentDevice();
  const { isMobile, isDesktop } = useDeviceType();
  const hasNewNavigation = useHasNewNavigation();

  return (
    <SWRConfig value={{ fetcher: headerDefaultFetcher }}>
      <ErrorBoundary>{userAgentDevice === 'mobile' && <AppBanner />}</ErrorBoundary>

      <ResponsiveHeaderLayout
        logo={<LinkedLogo />}
        searchField={({ isMobileSearchAlwaysVisible }) =>
          isMobile && isMobileSearchAlwaysVisible ? (
            <ArrowBox left={`calc(100% - ${SEARCH_ICON_MOBILE_RIGHT_POSITION})`}>
              <DynamicSearchField />
            </ArrowBox>
          ) : (
            <DynamicSearchField />
          )
        }
        icons={() => <SlotsDefault />}
        navigation={
          <>
            {((isDesktop && hasNewNavigation.desktop) ||
              (!isDesktop && hasNewNavigation.mobile)) && (
              <ResponsiveNavigationLoader
                mode={userAgentDevice === 'desktop' && isDesktop ? desktopNavigationMode : 'click'}
              />
            )}
            {isDesktop && !hasNewNavigation.desktop && <DesktopNavigation />}
            {!isDesktop && !hasNewNavigation.mobile && <MobileNavigation />}
          </>
        }
      />

      <ErrorBoundary>
        <InspiringSearchOnboardingModal />
      </ErrorBoundary>
    </SWRConfig>
  );
};
