import { Search32 } from '@packages/themes/icons';
import { defineMessage, useIntl } from 'react-intl';
import { Slot } from './Slot';
import { DynamicSearchField } from '../SearchField';
import { ArrowBox } from './ArrowBox';
import { useSearchQuery } from '../../useSearchQuery';

const title = defineMessage({
  id: 'slot.search.label',
  defaultMessage: 'Suche',
});

/** Search slot is only displayed on mobile devices and shows the searchfield in the popover */
export const SearchSlot = () => {
  const { formatMessage } = useIntl();
  const searchQuery = useSearchQuery();

  // for SERP routes the SearchField is displayed directly in Header.tsx
  return searchQuery ? (
    <Slot icon={<Search32 sx={{ color: 'primary.main' }} />} />
  ) : (
    <Slot title={formatMessage(title)} linkAriaLabel={formatMessage(title)} icon={<Search32 />}>
      {(left, closePopover) => (
        <ArrowBox left={left + 6}>
          <DynamicSearchField autofocus onSearch={closePopover} />
        </ArrowBox>
      )}
    </Slot>
  );
};
