import React from 'react';
import { Box, Divider, Stack, Typography } from '@packages/shared';
import { ArrowMediumRight32, Checkmark32 } from '@packages/themes/icons';
import type { SelectValuesFragmentFragment } from '@packages/gql/generated/shopping/graphql';
import { VariationIcon } from '../../VariationIcon';
import { getIsAvailable } from '../../../helpers';
import { variationRowStyles } from './styles';

export type VariationRowProps = {
  /**
   * The GraphQL value data for the given product
   */
  value: SelectValuesFragmentFragment['values'][number];
  /**
   * indicates if one value has an image and the column has to be rendered
   */
  hasImage: boolean;
  /**
   * method that will be executed on variation click
   * @param sku the sku of the clicked variation
   * @returns void
   */
  clickHandler: (sku: SelectValuesFragmentFragment['values'][number]['sku']) => void;
};

/**
 * DialogVariationSelectionRow component
 *
 * used to display a dimension value in the dialog
 *
 * */
export const VariationRow = ({
  value: { displayText, image, isSelected, rgb, variationMainImage, sku, availability },
  hasImage,
  clickHandler,
}: VariationRowProps) => {
  const isDisabled = !getIsAvailable(availability);
  const isClickable = !(isSelected === true);

  return (
    <>
      <Stack
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 1.5,
          p: 1.5,
          backgroundColor: isSelected ? 'grey.light' : 'common.white',
          cursor: isClickable ? 'pointer' : 'auto',
          ':hover': {
            backgroundColor: 'grey.light',
          },
        }}
        onClick={() => {
          if (isClickable) {
            clickHandler(sku);
          }
        }}
      >
        {hasImage && (
          <VariationIcon
            imageSize="DIALOG"
            rgb={rgb}
            image={variationMainImage || image}
            displayText={displayText}
          />
        )}
        <Typography
          variant="body2"
          sx={
            isDisabled
              ? variationRowStyles.variationName.disabled
              : variationRowStyles.variationName.default
          }
        >
          {displayText}
        </Typography>
        {isDisabled ? (
          <Box sx={variationRowStyles.indicator.disabled}>
            <Typography variant="body2" color="error.main" component="strong">
              {availability!.message}
            </Typography>
          </Box>
        ) : (
          <Box sx={variationRowStyles.indicator.default}>
            {isSelected ? <Checkmark32 /> : <ArrowMediumRight32 />}
          </Box>
        )}
      </Stack>
      <Divider />
    </>
  );
};
