import { forwardRef } from 'react';

import type { StackProps } from '@packages/shared';
import { Stack } from '@packages/shared';

import { ResponsiveBackground } from './ResponsiveBackground';

export type SecondaryNavigationContainerProps = Pick<StackProps, 'children' | 'onMouseLeave'> & {
  isVisible: boolean;
};
export const SecondaryNavigationContainer = forwardRef<
  HTMLDivElement | null,
  SecondaryNavigationContainerProps
>(({ children, onMouseLeave, isVisible }, ref) => (
  <Stack
    sx={{
      position: 'relative',
      gridArea: { xs: 'content', lg: 'anchor' },
      pointerEvents: 'none',
      overflowY: { xs: 'auto', lg: 'visible' },
    }}
  >
    <Stack
      ref={ref}
      sx={{
        position: { xs: 'static', lg: 'absolute' },
        top: 0,
        left: 0,
        right: 0,
        flex: 1,
        zIndex: (theme) => theme.zIndex.tooltip,
        display: isVisible ? 'flex' : 'none',
        pointerEvents: 'initial',
      }}
      onMouseLeave={onMouseLeave}
    >
      <ResponsiveBackground
        sx={{
          backgroundColor: { xs: 'transparent', lg: 'grey.light' },
        }}
      />

      {children}
    </Stack>
  </Stack>
));
