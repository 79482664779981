'use client';

import { defineMessage, useIntl } from 'react-intl';

import { Link } from '@packages/shared';
import { Logo } from '@packages/themes/icons';
import type { GenericTrackingEvent } from '@packages/tracking';
import { useTracking } from '@packages/tracking';

const logoTitle = defineMessage({
  id: 'logo.title',
  defaultMessage: 'zur Startseite',
});
/** Header tenant logo. Links to home */
export const LinkedLogo = () => {
  const { formatMessage } = useIntl();

  const dispatchGtmEvent = useTracking();

  const trackLogoClick = () => {
    dispatchGtmEvent<GenericTrackingEvent>({
      event: 'suche-event',
      eventValue: 'homepage=storefront',
    });
  };

  return (
    <Link href="/" noLinkStyle title={formatMessage(logoTitle)} onClick={trackLogoClick}>
      <Logo
        sx={{
          position: 'relative',
          height: [25, 25, 25, 48],
          width: 'auto',
          verticalAlign: 'middle',
        }}
      />
    </Link>
  );
};
