import { useAbTest, useMouseMovementOutcome } from '@packages/abtesting';
import { useConfig, useCookies } from '@packages/utilities';

export const NAVIGATION_TEST_ID = '2024_03_navigation';

/**
 * Determines if the new navigation should be used, based on combined config settings, A/B-Testing participation and cookies.
 *
 * Distinguishes between desktop and mobile navigation, both can be toggled separately.
 *
 * Cookies: `feature/newDesktopNavigation` and `feature/newMobileNavigation` respectively, content does not matter as long as the cookie is present.
 */
export const useHasNewNavigation = () => {
  const {
    header: { hasNewNavigation },
  } = useConfig();

  const { getCookies } = useCookies();
  const cookies = getCookies();

  const hasDesktopCookie = 'feature/newDesktopNavigation' in cookies;
  const hasMobileCookie = 'feature/newMobileNavigation' in cookies;

  useMouseMovementOutcome(NAVIGATION_TEST_ID);
  const test = useAbTest(NAVIGATION_TEST_ID);

  const isPartOfTestGroup = test?.variant.treatment === 'test';

  return {
    desktop: hasNewNavigation || isPartOfTestGroup || hasDesktopCookie,
    mobile: isPartOfTestGroup || hasMobileCookie,
  };
};
