export enum GlycerinAvailability {
  IMMEDIATELY = 'AvailabilityType.IMMEDIATELY',
  NOTIMMEDIATELY = 'AvailabilityType.NOTIMMEDIATELY',
  UNAVAILABLE = 'AvailabilityType.UNAVAILABLE',
  UNKNOWN = 'AvailabilityType.UNKNOWN',
}

export enum GlycerinRatingStatus {
  RATED = 'RatingStatus.RATED',
  UNAVAILABLE = 'RatingStatus.UNAVAILABLE',
  UNRATED = 'RatingStatus.UNRATED',
}

export enum GlycerinPlacement {
  HEADER = 'Placement.HEADER',
  CONTENT = 'Placement.CONTENT',
  FOOTER = 'Placement.FOOTER',
  OVERLAY = 'Placement.OVERLAY',
  LISTING = 'Placement.LISTING',
}

export enum GlycerinVideoAction {
  PLAY = 'VideoAction.PLAY',
  PAUSE = 'VideoAction.PAUSE',
}

export enum GlycerinLoginStatus {
  SOFTLOGGEDIN = 'LoginStatus.SOFTLOGGEDIN',
  LOGGEDIN = 'LoginStatus.LOGGEDIN',
  UNKNOWN = 'LoginStatus.UNKNOWN',
  PERMALOGGEDIN = 'LoginStatus.PERMALOGGEDIN',
  NOTLOGGEDIN = 'LoginStatus.NOTLOGGEDIN',
}

export enum GlycerinFilterType {
  MULTISELECT = 'FilterType.MULTISELECT',
  RANGE = 'FilterType.RANGE',
  SINGLESELECT = 'FilterType.SINGLESELECT',
}

export enum GlycerinTileType {
  PRODUCT = 'TileType.PRODUCT',
  AD = 'TileType.AD',
  CAMPAIGN = 'TileType.CAMPAIGN',
  CATEGORY = 'TileType.CATEGORY',
}

export enum GlycerinSortMethod {
  NEW = 'SortMethod.NEW',
  DISCOUNT = 'SortMethod.DISCOUNT',
  OTHER = 'SortMethod.OTHER',
  POPULARITY = 'SortMethod.POPULARITY',
  PRICEASCENDING = 'SortMethod.PRICEASCENDING',
  PRICEDESCENDING = 'SortMethod.PRICEDESCENDING',
  RATING = 'SortMethod.RATING',
  SEARCHLOGIC = 'SortMethod.SEARCHLOGIC',
  PRANK = 'SortMethod.PRANK',
}

export enum GlycerinDestinationType {
  REGULAR = 'DestinationType.REGULAR',
  STOREPICKUP = 'DestinationType.STOREPICKUP',
  PARCELSHOP = 'DestinationType.PARCELSHOP',
  PARCELSTATION = 'DestinationType.PARCELSTATION',
  EMAIL = 'DestinationType.EMAIL',
}

export enum GlycerinPaymentType {
  OTHER = 'PaymentType.OTHER',
  INVOICE = 'PaymentType.INVOICE',
  CREDITCARD = 'PaymentType.CREDITCARD',
  PAYPAL = 'PaymentType.PAYPAL',
  PREPAYMENT = 'PaymentType.PREPAYMENT',
  DEBIT = 'PaymentType.DEBIT',
  DEFERREDPAYMENT = 'PaymentType.DEFERREDPAYMENT',
  SOFORTUEBERWEISUNG = 'PaymentType.SOFORTUEBERWEISUNG',
  KLARNA = 'PaymentType.KLARNA',
  RATEPAY = 'PaymentType.RATEPAY',
  AMAZONPAY = 'PaymentType.AMAZONPAY',
  GOOGLEPAY = 'PaymentType.GOOGLEPAY',
  PAYDIREKT = 'PaymentType.PAYDIREKT',
  STOREPAYMENT = 'PaymentType.STOREPAYMENT',
  GIROPAY = 'PaymentType.GIROPAY',
  VOUCHER = 'PaymentType.VOUCHER',
  CASHONDELIVERY = 'PaymentType.CASHONDELIVERY',
  INSTALLMENT = 'PaymentType.INSTALLMENT',
}

export enum GlycerinShippingType {
  REGULAR = 'ShippingType.REGULAR',
  EXPRESS = 'ShippingType.EXPRESS',
  PREFERREDTIMEOFDELIVERY = 'ShippingType.PREFERREDTIMEOFDELIVERY',
  FLATRATE = 'ShippingType.FLATRATE',
}

export enum GlycerinErrorSeverity {
  WARNING = 'Severity.WARNING',
  CRITICAL = 'Severity.CRITICAL',
}

export enum GlycerinSuggestionType {
  PRODUCT = 'SuggestionType.PRODUCT',
  CATEGORY = 'SuggestionType.CATEGORY',
  TERM = 'SuggestionType.TERM',
}

export enum GlycerinPageType {
  OTHER = 'PageType.OTHER',
  HOMEPAGE = 'PageType.HOMEPAGE',
  CATEGORYOVERVIEW = 'PageType.CATEGORYOVERVIEW',
  CHECKOUT = 'PageType.CHECKOUT',
  SHIPPING = 'PageType.SHIPPING',
  PAYMENT = 'PageType.PAYMENT',
  CHECKANDORDER = 'PageType.CHECKANDORDER',
  ORDERCONFIRMATION = 'PageType.ORDERCONFIRMATION',
  ORDERINFO = 'PageType.ORDERINFO',
  CUSTOMERREGISTRATION = 'PageType.CUSTOMERREGISTRATION',
  LOGIN = 'PageType.LOGIN',
  WISHLIST = 'PageType.WISHLIST',
  CUSTOMERACCOUNT = 'PageType.CUSTOMERACCOUNT',
  CORPORATE = 'PageType.CORPORATE',
  ORDERFORM = 'PageType.ORDERFORM',
  INFO = 'PageType.INFO',
  ERROR = 'PageType.ERROR',
  BLOG = 'PageType.BLOG',
  PRODUCTDETAIL = 'PageType.PRODUCTDETAIL',
  PRODUCTLISTING = 'PageType.PRODUCTLISTING',
  SEARCHRESULT = 'PageType.SEARCHRESULT',
  CART = 'PageType.CART',
}
