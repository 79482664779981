import type { MouseEvent } from 'react';
import { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import useSWR from 'swr';
import { useConfig, logger } from '@packages/utilities';
import { Typography, Box } from '@packages/shared';
import { useItemSelection } from '../useItemSelection';
import { HistoryItem } from './HistoryItem';
import { createApiUrl, headerDefaultFetcher } from '../../../api';
import { useSearchFieldTracking } from '../useSearchFieldTracking';

interface SearchHistoryProps {
  /** Selected suggest keyboard item */
  cursor: number;
  /** Callback that is called when the user clicks the arrow button to accept a history item as the current input value without immediately triggering a search navigation */
  onAccept?: (value: string) => void;
  /** Update input value */
  setValue: (value: string, displayOnly?: boolean) => void;
  /**
   * Token for fetching data (will be removed when swr completely implements suspense support)
   @deprecated
  */
  jwtToken: string;
}

export const deleteResource = (
  url: string,
  token: string,
  body?: string,
): Promise<string[] | unknown> =>
  fetch(url, {
    method: 'DELETE',
    headers: {
      'x-ec-token': token,
      'Content-Type': 'text/plain;charset=UTF-8',
    },
    body,
  })
    .then((res) => res.json())
    .catch((e) => {
      logger.error(e, 'Error on deleting search history items');
    });

export const SearchHistory = ({ onAccept, setValue, cursor, jwtToken }: SearchHistoryProps) => {
  const { formatMessage } = useIntl();
  const {
    apiEndpoints: { historyApiNew, historyDeleteAllNew },
  } = useConfig();

  const { dispatchSearchGtmEvent } = useSearchFieldTracking();

  const { data, error, mutate } = useSWR<string[] | unknown>(
    [createApiUrl(historyApiNew), jwtToken],
    headerDefaultFetcher,
    {
      suspense: true,
    },
  );

  // in some circumstances, API returns an error object instead of a string[]
  const dataArray = Array.isArray(data) ? data : undefined;

  // TODO: remove non-null assertion after swr adapts its types to "suspense pattern"
  const [selectedItem, selectedIndex] = useItemSelection(cursor, dataArray);

  useEffect(() => {
    setValue(selectedItem ?? '', true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  const makeDeleteHandler = (entryToDelete: string) => async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    // TODO: remove non-null assertion after swr types are adapted to "suspense pattern"
    await mutate(deleteResource(createApiUrl(historyApiNew), jwtToken, entryToDelete), {
      optimisticData: dataArray?.filter((entry) => entry !== entryToDelete),
      rollbackOnError: true,
      revalidate: false,
    });
  };

  const resetHistory = async (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();

    await mutate(deleteResource(createApiUrl(historyDeleteAllNew), jwtToken), {
      optimisticData: [],
      rollbackOnError: true,
      revalidate: false,
    });
  };

  const messages = defineMessages({
    searchHistoryTitle: {
      id: 'search.history.title',
      defaultMessage: 'Deine Suchhistorie',
    },
    deletAllEntries: {
      id: 'searchbar.history.delete.all',
      defaultMessage: 'Suchverlauf löschen',
    },
  });

  // TODO: remove checking if data exists after swr adapts its types to "suspense pattern"
  if (error || !dataArray || dataArray.length === 0) {
    return null;
  }

  return (
    <>
      <Box sx={{ p: 1, bgcolor: 'grey.main' }}>
        <Typography sx={{ fontWeight: 600, mt: 0.5, mr: 4, mb: 0.75, ml: 0.75 }}>
          {formatMessage(messages.searchHistoryTitle)}
        </Typography>
      </Box>
      {dataArray.map((entry, i) => (
        <HistoryItem
          selected={selectedIndex === i}
          onDelete={makeDeleteHandler(entry)}
          onClick={() => dispatchSearchGtmEvent(entry)}
          onArrowClick={() => onAccept?.(entry)}
          url={`/s/${entry}/?searchHistory=true`}
          key={entry}
          label={entry}
        />
      ))}
      <HistoryItem
        testId="history-delete-all-items"
        selected={selectedIndex === dataArray.length}
        label={formatMessage(messages.deletAllEntries)}
        onClick={resetHistory}
        sx={{ bgcolor: 'grey.main' }}
        withDeleteButton={false}
        withAdoptButton={false}
      />
    </>
  );
};
