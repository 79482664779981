import { useRef, useState } from 'react';
import type { RefObject, FC } from 'react';
import dynamic from 'next/dynamic';

import { Box, ClickAwayListener, DialogHeader, useDeviceType, useUrl } from '@packages/shared';
import { useAbTesting } from '@packages/abtesting';

import { ResponsiveNavigationMainItem } from './ResponsiveNavigationMainItem';
import type { NavigationItem, RawNavigationItem } from './navigationTypes';
import { useCleanedNavData } from './useCleanedNavData';
import type { UseTreeNavigationPopupOptions } from './Flyout/useTreeNavigationPopup';
import { useTreeNavigationPopup } from './Flyout/useTreeNavigationPopup';
import { useNavigationTracking } from './useNavigationTracking';
import { HamburgerButton } from './Internals/HamburgerButton';
import { useToggleTransition } from './useToggleTransition';
import { SecondaryNavigationContainer } from './Internals/SecondaryNavigationContainer';
import { PrimaryNavigationContainer } from './Internals/PrimaryNavigationContainer';
import { ResponsiveNavigationLayout } from './Internals/ResponsiveNavigationLayout';
import { SubMenuTabs } from './Internals/SubMenuTabs';
import { AccountTabContent } from './Internals/AccountTabContent';
import { NAVIGATION_TEST_ID } from '../../useHasNewNavigation';

const ResponsiveFlyout = dynamic(() => import('./Flyout/ResponsiveFlyout'), {
  ssr: false,
});

export type ResponsiveNavigationProps = {
  /** The nav tree, as a polyforest (in the graph theory sense) of navigation items */
  items: RawNavigationItem[];
} & Pick<UseTreeNavigationPopupOptions, 'mode' | 'onFirstInteraction'>;

/**
 * Navigation component, which displays a horizontal navigation bar on desktop. Shows {@link ResponsiveFlyout} on user interaction, to enable navigating the nav tree.
 *
 * If possible, mobile navigation will be implemented later as a responsive variant of this component, instead of as a different JS-switched component, to avoid layout shifts on page load and other issues with having distinct components for each breakpoint.
 */
export const ResponsiveNavigation: FC<ResponsiveNavigationProps> = ({
  items,
  mode = 'click',
  onFirstInteraction,
}) => {
  const [tab, setTab] = useState<'menu' | 'account'>('menu');

  const {
    shouldBeMounted: hamburgerShouldBeMounted,
    classNames: hamburgerClassNames,
    toggle: toggleHamburgerMenu,
    hide: hideHamburgerMenu,
  } = useToggleTransition(300);

  const { isDesktop } = useDeviceType();

  const url = useUrl();
  const {
    navigationDirection,
    showPopup,
    isSelected,
    isRoot,
    selectMainItem,
    selectPopupItem,
    backToParent,
    closePopup,
  } = useTreeNavigationPopup({ onFirstInteraction });

  const closeHamburgerMenu = () => {
    closePopup();
    hideHamburgerMenu();
  };
  const allowHoverInteractions = isDesktop && mode === 'hover';

  const onFilteredMouseLeave =
    (allowedTarget: RefObject<HTMLDivElement | null>) =>
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!allowHoverInteractions) return;

      const mouseIsEnteringAllowedElement =
        event.relatedTarget instanceof Node && allowedTarget.current?.contains(event.relatedTarget);

      if (!mouseIsEnteringAllowedElement) {
        closePopup();
      }
    };

  // close popup when route changes, just in case the user manages to navigate without closing the navigation
  const lastUrl = useRef(url);
  if (lastUrl.current !== url) {
    closeHamburgerMenu();
    closePopup();
  }
  lastUrl.current = url;

  const { trackNavigationTo } = useNavigationTracking();

  const { setOutcome } = useAbTesting();

  const handleNavigate = (item: NavigationItem) => {
    setOutcome(NAVIGATION_TEST_ID, { COF: 1 });
    trackNavigationTo(item);
    closePopup();
    closeHamburgerMenu();
  };

  const selectMainItemWithTracking: typeof selectMainItem = (...args) => {
    setOutcome(NAVIGATION_TEST_ID, { OPEN: 1 });
    selectMainItem(...args);
  };

  const { cleanItems, flatItems } = useCleanedNavData(items);

  const selectedItem = flatItems.find(isSelected);

  const primaryNavigationRef = useRef<HTMLDivElement | null>(null);
  const secondaryNavigationRef = useRef<HTMLDivElement | null>(null);

  return (
    <ResponsiveNavigationLayout
      hamburgerClassNames={hamburgerClassNames}
      hamburgerShouldBeMounted={hamburgerShouldBeMounted}
      button={
        <HamburgerButton
          onClick={() => {
            if (!hamburgerShouldBeMounted) {
              setOutcome(NAVIGATION_TEST_ID, { OPEN: 1 });
            }
            toggleHamburgerMenu();
          }}
        />
      }
      isLevel2Visible={showPopup}
    >
      <Box sx={{ display: { lg: 'none' }, gridArea: 'header' }}>
        <DialogHeader
          title={selectedItem?.displayName ?? ''}
          onBack={selectedItem ? backToParent : closeHamburgerMenu}
          onClose={closeHamburgerMenu}
        />
      </Box>

      <SubMenuTabs
        value={tab}
        onChange={(value) => {
          setTab(value);
          closePopup();
        }}
      />

      <ClickAwayListener
        onClickAway={() => {
          closePopup?.();
        }}
      >
        <Box sx={{ display: 'contents' }}>
          {(isDesktop || tab === 'menu') && (
            <PrimaryNavigationContainer
              ref={primaryNavigationRef}
              isVisible={tab === 'menu'}
              onMouseLeave={onFilteredMouseLeave(secondaryNavigationRef)}
            >
              {cleanItems.map((item) => (
                <ResponsiveNavigationMainItem
                  key={item.uri}
                  href={item.uri}
                  imageUrl={item.image}
                  allowNavigation={allowHoverInteractions || item.children.length === 0}
                  isOpen={showPopup && isRoot(item)}
                  // trailing slash is stripped for the comparison, because `url` has no trailing slash by default
                  isActive={item.uri !== undefined && url.startsWith(item.uri.replace(/\/$/, ''))}
                  onHover={() => {
                    if (allowHoverInteractions) {
                      selectMainItemWithTracking(item, true);
                    }
                  }}
                  onClick={() => {
                    selectMainItemWithTracking(item);
                  }}
                  onNavigate={() => handleNavigate(item)}
                >
                  {item.displayName}
                </ResponsiveNavigationMainItem>
              ))}
            </PrimaryNavigationContainer>
          )}

          {!isDesktop && tab === 'account' && <AccountTabContent />}

          <SecondaryNavigationContainer
            ref={secondaryNavigationRef}
            isVisible={showPopup}
            onMouseLeave={onFilteredMouseLeave(primaryNavigationRef)}
          >
            <ResponsiveFlyout
              item={selectedItem}
              direction={navigationDirection}
              onBack={!selectedItem || isRoot(selectedItem) ? undefined : backToParent}
              onClose={closePopup}
              onItemClick={selectPopupItem}
              onNavigate={handleNavigate}
            />
          </SecondaryNavigationContainer>
        </Box>
      </ClickAwayListener>
    </ResponsiveNavigationLayout>
  );
};
