import type { router } from 'next/client';

const DUMMY_ORIGIN = 'http://dummy.dummy';

export const mergeQueryParameters = (url: string, additionalQuery: typeof router.query) => {
  // some URLs we get from the API are protocol-relative (starting with '//')
  // protocol-relative URLs don't work with whatwg standard URL object
  // https is assumed as the default protocol
  const sanitizedUrl = url.replace(/^\/\//, 'https://');

  const isAbsolute = /^https?:\/\//.test(sanitizedUrl);

  const newUrl = new URL(sanitizedUrl, isAbsolute ? undefined : DUMMY_ORIGIN); // dummy URL, just to use the URLSearchParams API
  const searchParams = new URLSearchParams(newUrl.search);

  for (const key in additionalQuery) {
    if (additionalQuery) {
      const value = additionalQuery[key];
      if (typeof value === 'string') {
        searchParams.set(key, value);
      } else if (Array.isArray(value)) {
        value.forEach((item) => {
          if (typeof item === 'string') {
            searchParams.append(key, item);
          }
        });
      }
    }
  }

  searchParams.sort();

  newUrl.search = searchParams.toString();

  return isAbsolute ? newUrl.toString() : newUrl.toString().replace(DUMMY_ORIGIN, '');
};
