import type { ReactNode, Dispatch, SetStateAction } from 'react';
import {
  Box,
  Button,
  Image,
  Grid,
  Link,
  Stack,
  Typography,
  useDeviceType,
  useI18n,
} from '@packages/shared';
import { MiniDetailViewFragmentFragmentDoc } from '@packages/gql/generated/shopping/MiniDetailViewFragmentFragmentDoc';
import { getFragmentData } from '@packages/gql/generated/shopping';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { useIntl } from 'react-intl';
import { EfficiencyFlagList } from '../../EfficiencyFlagList';
import { Price } from '../../Price';
import { VariationSelect } from '../../VariationSelect';
import { QuantitySelection } from '../../QuantitySelection';
import { AvailabilityInfo } from '../../AvailabilityInfo';
import { miniDVMessages } from '../messages';

/* GraphQL */ `
  fragment MiniDetailViewFragment on Product {
    sku
    name
    media {
      type
      url
    }
    price {
      currency
      ...ProductPriceFragment
    }
    dimensions {
      values {
        displayText
      }
      ...DimensionFragment
    }
    tireEfficiency {
      link
      flags {
        level
        type
      }
    }
    powerEfficiencyFlags {
      color
      level
      link
      text
      hasOldLabel
    }
    ...AvailabilityInfoFragment
    ...EfficiencyFlagListFragment
  }
`;

export type MiniDVProps = {
  /**
   * MiniDV product data
   */
  data: FragmentType<typeof MiniDetailViewFragmentFragmentDoc>;
  /**
   * sku of the product
   */
  selectedVariation: string | null;
  /**
   * setter for selectedVariation
   */
  setSelectedVariation: Dispatch<SetStateAction<string | null>>;
  /**
   * quantity of the lineItem
   */
  quantity: number;
  /**
   * Servicelayer component for AvailabilityInfo
   */
  serviceLayer: ReactNode;
};

export const MiniDV = ({
  data,
  selectedVariation,
  setSelectedVariation,
  quantity,
  serviceLayer,
}: MiniDVProps) => {
  const product = getFragmentData(MiniDetailViewFragmentFragmentDoc, data);

  const {
    name,
    media,
    price,
    tireEfficiency,
    powerEfficiencyFlags,
    sku: productSku,
    dimensions,
  } = product;
  const { language, localizeUrl } = useI18n();
  const { formatMessage } = useIntl();
  const { isMobile } = useDeviceType();

  return (
    <Grid container sx={{ overflowY: 'auto' }}>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          position: 'relative',
          p: 2.5,
          '::after': {
            content: '""',
            position: 'absolute',
            zIndex: 10,
            width: ['auto', 'auto', '1px'],
            height: ['1px', '1px', 'auto'],
            top: ['auto', 'auto', 20],
            bottom: [0, 0, 20],
            right: 0,
            left: [0, 0, 'auto'],
            bgcolor: 'grey.main',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: ['row', 'row', 'column'],
            gap: 3,
            mb: 3,
          }}
        >
          <Box sx={{ flex: ['0 0 33%', '0 0 33%', 'auto'], width: '100%' }}>
            <Box sx={{ position: 'relative', width: '100%', height: 0, pt: '100%' }}>
              <Image src={media[0].url} alt={name} fill style={{ objectFit: 'contain' }} />
            </Box>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              gap: [0.875, 0.875, 1],
            }}
          >
            <Typography fontWeight="fontWeightSemiBold" variant={isMobile ? 'body2' : 'body1'}>
              {name}
            </Typography>
            <AvailabilityInfo data={product} serviceLayer={serviceLayer} />
            <Price data={price} />
            {((tireEfficiency && tireEfficiency.flags.length > 0) ||
              (powerEfficiencyFlags && powerEfficiencyFlags.length > 0)) && (
              <EfficiencyFlagList data={product} />
            )}
            <QuantitySelection lineItemQuantity={quantity} />
            <Link href={localizeUrl(`/p/${productSku}`, language)} noLinkStyle>
              <Button fullWidth>{formatMessage(miniDVMessages.artikelButton)}</Button>
            </Link>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} sx={{ p: 2.5 }}>
        <Stack spacing={3} mb={2.5}>
          {dimensions.map(
            (dimension, i) =>
              dimension.values.length > 0 && (
                <VariationSelect
                  dimensionData={dimension}
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  onChange={setSelectedVariation}
                  selectedVariation={selectedVariation}
                  sampleOrderLink={null}
                />
              ),
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};
