import type { ReadonlyURLSearchParams } from 'next/navigation';

export const getVariationFormattedLink = (
  sku: string | null,
  searchParams: ReadonlyURLSearchParams | null,
  pathname: string | null,
): string => {
  const newSearchParams = new URLSearchParams(searchParams?.toString());

  if (sku) {
    newSearchParams.set('sku', sku);
  }

  return `${pathname}?${newSearchParams.toString()}`;
};
