import type { GTMEventGlycerinSearch, GlycerinSearchSuggestion } from '@packages/tracking';
import { useTracking, GlycerinSuggestionType } from '@packages/tracking';
import type { Result, Suggestion } from './types';

const transformSuggestionToGlycerinData = (
  suggestion: Suggestion,
  suggestionType: GlycerinSuggestionType,
): GlycerinSearchSuggestion => ({
  suggestionType,
  ...(suggestionType === GlycerinSuggestionType.TERM && { term: suggestion.value }),
  ...(suggestionType === GlycerinSuggestionType.CATEGORY && { category: suggestion.value }),
  ...(suggestionType === GlycerinSuggestionType.PRODUCT && {
    productId: suggestion.url,
    imageType: suggestion.imageUrl,
    origin: 'Suggestion',
  }),
});

const transformSuggestionCategoryResultsToSuggestions = (
  suggestionCategoryResults: Result[],
): GlycerinSearchSuggestion[] => {
  const [searchresults, categories, articles] = suggestionCategoryResults;

  return ([] as GlycerinSearchSuggestion[])
    .concat(
      searchresults.suggestCategoryResult.suggests.map((searchResultSuggestion) =>
        transformSuggestionToGlycerinData(searchResultSuggestion, GlycerinSuggestionType.TERM),
      ),
    )
    .concat(
      categories.suggestCategoryResult.suggests.map((categorySuggestion) =>
        transformSuggestionToGlycerinData(categorySuggestion, GlycerinSuggestionType.CATEGORY),
      ),
    )
    .concat(
      articles.suggestCategoryResult.suggests.map((articleSuggestion) =>
        transformSuggestionToGlycerinData(articleSuggestion, GlycerinSuggestionType.PRODUCT),
      ),
    );
};

export const useSearchFieldTracking = () => {
  const dispatchGtmEvent = useTracking();

  const dispatchSearchGtmEvent = (
    searchTerm: string,
    selectedSuggestion?: Suggestion,
    allSuggestions?: Result[],
    suggestionType?: GlycerinSuggestionType,
  ) => {
    const suggestionUsed = selectedSuggestion
      ? transformSuggestionToGlycerinData(selectedSuggestion, suggestionType!)
      : undefined;

    const suggestions =
      selectedSuggestion && allSuggestions
        ? transformSuggestionCategoryResultsToSuggestions(allSuggestions)
        : [];

    const SearchData = {
      searchTermEntered: searchTerm,
      ...(selectedSuggestion && {
        suggestionUsed,
      }),
      ...(selectedSuggestion &&
        allSuggestions && {
          suggestions,
        }),
    };

    dispatchGtmEvent<GTMEventGlycerinSearch>({
      event: 'Search',
      SearchData,
    });
  };

  return {
    dispatchSearchGtmEvent,
  };
};
