import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgErrorCircle32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 33 33" sx={{
    ...sx
  }} {...other}><defs><circle id="error-circle-32_svg__a" cx={16} cy={16} r={12} /><path id="error-circle-32_svg__c" d="M4.45 7.45h3v-11h-3z" /></defs><g fillRule="evenodd" transform="translate(.5 .5)"><mask id="error-circle-32_svg__b" fill="#fff"><use xlinkHref="#error-circle-32_svg__a" /></mask><use xlinkHref="#error-circle-32_svg__a" fill="#444" /><g mask="url(#error-circle-32_svg__b)"><path d="M0 0h32v32H0z" /></g><use xlinkHref="#error-circle-32_svg__c" fill="#fff" transform="rotate(90 3.95 13.95)" /></g></SvgIcon>;
};
const Memo = memo(SvgErrorCircle32);
export default Memo;