import type { SxProps } from '@mui/material';
import { IconButton } from '@mui/material';
import {
  Box,
  CloseIconButton,
  ConditionalWrapper,
  Link,
  MenuItem,
  Typography,
} from '@packages/shared';
import { Adoptarrow32 } from '@packages/themes/icons';
import type { MouseEventHandler } from 'react';

interface HistoryItemProps {
  /**
   data-testid for history item
   @default history-item
  */
  testId?: string;
  /** Link url */
  url?: string;
  /** Click on history item */
  onClick?: MouseEventHandler<HTMLElement>;
  /** Should delete button be added */
  withDeleteButton?: boolean;
  /** Should adopt button be added */
  onDelete?: MouseEventHandler<HTMLButtonElement>;
  /** Click on delete button */
  withAdoptButton?: boolean;
  /** Callback fired when the user clicks the arrow to the right of the item */
  onArrowClick?: () => void;
  /** Text label */
  label: string;
  /** Is entry selected */
  selected?: boolean;
  /** MUI System sx prop */
  sx?: SxProps;
}

export const HistoryItem = ({
  testId = 'history-item',
  url,
  onClick,
  onDelete,
  onArrowClick,
  withDeleteButton = true,
  withAdoptButton = true,
  label,
  selected,
  sx,
}: HistoryItemProps) => (
  <ConditionalWrapper
    condition={!!url}
    wrapper={(ch) => (
      <Link href={url!} noLinkStyle id={selected ? 'selected-item' : ''}>
        {ch}
      </Link>
    )}
  >
    <MenuItem
      id={!url && selected ? 'selected-item' : ''}
      data-testid={testId}
      sx={{
        display: 'flex',
        alignItems: 'center',
        '.MuiTypography-body1': {
          flexGrow: 1,
        },
        height: 42,
        p: 0,
        pl: 1,
        ...sx,
      }}
      selected={selected}
      onMouseDown={(e) => {
        // avoid onBlur before following link
        e.preventDefault();
      }}
      onClick={onClick}
    >
      <Box sx={{ width: 48 }}>
        {withDeleteButton && (
          <CloseIconButton
            data-testid="history-delete-entry"
            sx={{ mr: 1.5, color: 'text.dark' }}
            onClick={onDelete}
          />
        )}
      </Box>
      <Typography variant="body1">{label}</Typography>
      {withAdoptButton && (
        <IconButton
          size="small"
          aria-label="Insert into search field"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onArrowClick?.();
          }}
        >
          <Adoptarrow32 />
        </IconButton>
      )}
    </MenuItem>
  </ConditionalWrapper>
);
