import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgSuccessCircle32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 33 32" sx={{
    ...sx
  }} {...other}><defs><circle id="success-circle-32_svg__a" cx={16} cy={16} r={12} /><path id="success-circle-32_svg__c" d="M4.45 6.74v-6h3v9h-5v-3z" /></defs><g fillRule="evenodd" transform="translate(.5)"><mask id="success-circle-32_svg__b" fill="#fff"><use xlinkHref="#success-circle-32_svg__a" /></mask><use xlinkHref="#success-circle-32_svg__a" /><g mask="url(#success-circle-32_svg__b)"><path d="M0 0h32v32H0z" /></g><use xlinkHref="#success-circle-32_svg__c" fill="#fff" transform="rotate(45 -1.621 23.518)" /></g></SvgIcon>;
};
const Memo = memo(SvgSuccessCircle32);
export default Memo;