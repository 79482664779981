import DOMPurify from 'isomorphic-dompurify';
import { Box } from '@packages/shared';
import type { SoftHyphenInjectorProps } from './types';

/**
 * `SoftHyphenInjector` is a React component that takes a text prop.
 * It replaces instances of "{--}" in the text with a soft hyphen HTML entity "&shy;".
 * The updated text is then rendered inside a `Box` component as HTML.
 *
 * @component
 * @param {SoftHyphenInjectorProps} props - The properties that define the `SoftHyphenInjector` component.
 * @param {string} props.text - The text to be processed and displayed.
 *
 * @example
 * <SoftHyphenInjector text="This is a test text with a soft hyphen{--}in the middle of the word." />
 *
 * @returns {JSX.Element} The `SoftHyphenInjector` component.
 */
export const SoftHyphenInjector = ({ text }: SoftHyphenInjectorProps): JSX.Element => {
  const replacedText = text.replace(/{--}/g, '&shy;');

  return (
    <Box
      className="soft-hyphen-injector"
      component="span"
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(replacedText) }}
    />
  );
};
