import { type ParsedUrlQuery } from 'querystring';

import { type Config } from '@packages/config';
import { getAllTestParticipations } from '@packages/abtesting/src/useAbTesting/utilities.cookie';

export const ONBOARDING_QUERY_PARAM = 'lassDichInspirieren';
export const INSPIRING_SEARCH_TEST_ID = '2024_7_SB01';

export const getTestGroup = (cookies: Record<string, string>) => {
  const activeTests = getAllTestParticipations(cookies);
  const test = activeTests.find(({ testId }) => testId === INSPIRING_SEARCH_TEST_ID);
  return test?.variant?.treatment;
};

/**
 * Get the activation status for Inspiring Search features (usable on server and client)
 */
export const getInspiringSearchSettingsInternal = (
  query: ParsedUrlQuery,
  config: Config,
  testGroup?: string,
) => {
  const {
    search: { isInspiringSearchEnabled: isEnabledInConfig },
  } = config;

  const forceAiEnhancement = !!query.alwaysAi || ONBOARDING_QUERY_PARAM in query;

  const isPartOfTestGroup = testGroup === 'test';

  const isInspiringSearchEnabled =
    (isEnabledInConfig && !isPartOfTestGroup) || !!query.enableAi || forceAiEnhancement;

  return {
    forceAiEnhancement,
    isInspiringSearchEnabled,
  };
};

/**
 * Get the activation status for Inspiring Search features (server-side)
 */
export const getInspiringSearchSettings = (
  query: ParsedUrlQuery,
  config: Config,
  cookies: Record<string, string>,
) => {
  const testGroup = getTestGroup(cookies);

  return getInspiringSearchSettingsInternal(query, config, testGroup);
};
