import { logger } from '@packages/utilities';
import type { FormatNumberOptions } from 'react-intl';
import { useIntl } from 'react-intl';

type UseFormatPriceReturn = {
  /**
   * method to format a price value as integer to a customized price string
   *
   * @param price the price as Integer (in cents)
   * @returns string
   */
  formatPrice: (price: number, currency: FormatNumberOptions['currency']) => string;
};

export const useFormatPrice = (): UseFormatPriceReturn => {
  const { formatNumber, locale } = useIntl();

  const formatPrice: UseFormatPriceReturn['formatPrice'] = (price, currency) => {
    try {
      // Intl.Numberformat will simply throw if the currency or locale doesn't make sense
      const numberFormatParts = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
      }).formatToParts(price / 100);

      const formattedCurrency = numberFormatParts.find(({ type }) => type === 'currency')!.value;

      return `${numberFormatParts
        .filter(
          ({ type }) =>
            (type === 'group' && !locale.endsWith('CH')) ||
            type === 'decimal' ||
            type === 'fraction' ||
            type === 'integer' ||
            type === 'minusSign',
        )
        .map(({ value: partValue }) => partValue)
        .join('')} ${formattedCurrency}`;
    } catch (error) {
      logger.error(error);
    }
    return formatNumber(price / 100, { style: 'currency', currency });
  };

  return {
    formatPrice,
  };
};
