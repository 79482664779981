import type { SxProps } from '@mui/material';
import {
  useTracking,
  type GlycerinImageClickPayload,
  type GTMEventGlycerinImageClick,
  type GlycerinDisplayImagePayload,
  // type GTMEventGlycerinDisplayImage,
} from '@packages/tracking';
import type { ImageProps } from 'next/image';
import Image from 'next/image';
import type { Ref } from 'react';
import { forwardRef, useRef, useState } from 'react';
import { Box } from '../Box';
import { akamaiLoader } from '../Image/loader';
// import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';

export interface FadedImageProps extends ImageProps {
  /** MUI System props for image wrapper: https://mui.com/system/the-sx-prop/ */
  sx?: SxProps;
  /** Duration of fade in transition in miliseconds
   @default: 200
   */
  transitionDuration?: number;
  /** type of component */
  component?: 'div' | 'span';
  /** Ref for the image element */
  ref?: Ref<HTMLImageElement>; // Change the type of ref to HTMLImageElement
  clickTrackingProps?: GlycerinImageClickPayload;
  displayTrackingProps?: GlycerinDisplayImagePayload;
}

/** Animated wrapper component for next/image: https://nextjs.org/docs/api-reference/next/image */
export const FadedImage = forwardRef<HTMLImageElement, FadedImageProps>(
  (
    {
      sx,
      transitionDuration = 200,
      component = 'div',
      clickTrackingProps,
      displayTrackingProps,
      onClick,
      ...props
    },
    ref,
  ) => {
    const [loaded, setLoaded] = useState(false);
    const wrapper = useRef<HTMLDivElement>(null);
    const dispatchGTMEvent = useTracking();
    const { category, detail, imageId, label, placement, source, target, variantIds, custom } =
      clickTrackingProps ?? {};
    /* const {
      imageId: displayTrackingImageId,
      source: displayTrackingSource,
      category: displayTrackingCategory,
      custom: displayTrackingCustom,
      detail: displayTrackingDetail,
      label: displayTrackingLabel,
      placement: displayTrackingPlacement,
      target: displayTrackingTarget,
      variantIds: displayTrackingVariantIds,
    } = displayTrackingProps ?? {}; */
    const { src } = props;
    let imagesrc: string;
    if (typeof src === 'object') {
      if ('default' in src) {
        imagesrc = src.default.src;
      } else {
        imagesrc = src.src;
      }
    } else {
      imagesrc = src;
    }

    // check if the image is fully visible
    /* useIntersectionObserver(wrapper, {
      intersectOnce: true,
      threshold: 1,
      callback: (isIntersecting) => {
        if (!isIntersecting) {
          return;
        }
        // intersectOnce is set to true, so this callback will only be called once
        dispatchGTMEvent<GTMEventGlycerinDisplayImage>({
          event: 'DisplayImage',
          DisplayImageData: {
            category: displayTrackingCategory ?? 'display-image',
            custom: displayTrackingCustom,
            detail: displayTrackingDetail,
            imageId: displayTrackingImageId,
            label: displayTrackingLabel,
            placement: displayTrackingPlacement,
            source: displayTrackingSource ?? imagesrc,
            target: displayTrackingTarget,
            variantIds: displayTrackingVariantIds,
          },
        });
      },
    }); */

    return (
      <Box
        component={component}
        sx={{ opacity: loaded ? 1 : 0, transition: `opacity ${transitionDuration}ms`, ...sx }}
      >
        <div ref={wrapper}>
          <Image
            onClick={(event) => {
              dispatchGTMEvent<GTMEventGlycerinImageClick>({
                event: 'ImageClick',
                ImageClickData: {
                  category: category ?? 'image-click',
                  detail,
                  imageId,
                  label,
                  placement,
                  source: source ?? imagesrc,
                  target,
                  variantIds,
                  custom,
                },
              });
              if (onClick) {
                onClick(event);
              }
            }}
            {...props}
            onLoadingComplete={() => setLoaded(true)}
            {...(ref && { ref })}
            loader={akamaiLoader}
            unoptimized={process.env.NODE_ENV === 'test'}
          />
        </div>
      </Box>
    );
  },
);
