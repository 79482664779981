import { useMemo } from 'react';

export const useItemSelection = <T extends any>(
  cursor: number,
  list?: Array<T>,
): [T | undefined, number] =>
  useMemo(() => {
    if (!list) {
      return [undefined, cursor];
    }
    const i = cursor > -1 ? cursor % (list.length + 1) : cursor;
    return [list[i], i];
  }, [cursor, list]);
