import { Link, MenuItem, MenuList, Typography, useTrackCampaignOnLoad } from '@packages/shared';
import { useConfig } from '@packages/utilities';
import { useEffect } from 'react';
import type { ServiceMenuEntry } from './ServiceMenuTypes';
import { useServiceMenuData } from './useServiceMenuData';

const ServiceSlotMenuItem = ({ data }: { data: ServiceMenuEntry }) => {
  const { translatable_title: title, translatable_url: url, is_external: isExternal } = data;
  const finalLink = url ?? '';
  const { trackClick, trackDisplay } = useTrackCampaignOnLoad(data, finalLink);

  useEffect(() => {
    if (url) {
      trackDisplay();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Link
      href={finalLink}
      key={title}
      noLinkStyle
      openInNewTab={isExternal}
      {...(url && { onClick: () => trackClick() })}
    >
      <MenuItem>
        <Typography variant="body2">{title}</Typography>
      </MenuItem>
    </Link>
  );
};

export const ServiceSlotMenuList = () => {
  const {
    headerSlots: { serviceApi },
  } = useConfig();

  const data = useServiceMenuData(serviceApi);

  return (
    <MenuList>
      {data.map((dataItem) => (
        <ServiceSlotMenuItem
          // NOTE theoretically each drupal item would have a unique id, but the id is currently not exposed
          key={dataItem.translatable_title}
          data={dataItem}
        />
      ))}
    </MenuList>
  );
};
