import React from 'react';
import { Box, Image } from '@packages/shared';
import type { ProductDimensionValue } from '@packages/gql/generated/shopping/graphql';
import { useResponsiveAkamaiTemplate } from '../../hooks';

const IMAGESIZEBUTTON = 24;

export type VariationIconProps = Pick<ProductDimensionValue, 'rgb' | 'image' | 'displayText'> & {
  /**
   * the size of the image Tile
   */
  imageSize: 'BUTTON' | 'DIALOG';
};

/**
 * Icon component
 *
 * used to display the selected dimension
 * opens dialog on click to select a different variation
 *
 * */
export const VariationIcon = ({ rgb, image, displayText, imageSize }: VariationIconProps) => {
  const { templateName, imageDimensions } = useResponsiveAkamaiTemplate('variationSelect');
  const IMAGESIZE = imageSize === 'BUTTON' ? IMAGESIZEBUTTON : imageDimensions.height;

  return (
    <Box
      sx={{
        borderRadius: '50%',
        overflow: 'hidden',
        height: `${IMAGESIZE}px`,
        width: `${IMAGESIZE}px`,
        flex: `0 0 ${IMAGESIZE}px`,
        backgroundColor: rgb ? `#${rgb}` : undefined,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'grey.main',
      }}
    >
      {image && (
        <Image
          src={imageSize === 'DIALOG' ? `${image}?${templateName}` : image}
          alt={displayText || ''}
          width={IMAGESIZE}
          height={IMAGESIZE}
        />
      )}
    </Box>
  );
};
