import type { FragmentType } from '@packages/gql/generated/shopping';
import { getFragmentData } from '@packages/gql/generated/shopping';
import { TilesValuesFragmentFragmentDoc } from '@packages/gql/generated/shopping/TilesValuesFragmentFragmentDoc';
import { Box, Link, Stack } from '@packages/shared';
import { useIsBot } from '@packages/tracking';
import { usePathname, useSearchParams } from 'next/navigation';
import { getIsAvailable, getVariationFormattedLink } from '../../helpers';
import type { SetSelectedVariation } from '../../types/types';
import { TileButton } from './TileButton';
import { getStyle } from './getStyle';

/* GraphQL */ `
  fragment TilesValuesFragment on ProductDimension {
    values {
      displayText
      isSelected
      sku
      availability {
        state
      }
    }
  }
`;

export type TilesDimensionProps = {
  /**
   * The GraphQL dimension data for the given product
   */
  dimensionData: FragmentType<typeof TilesValuesFragmentFragmentDoc>;
  onChange: SetSelectedVariation;
  selectedVariation: string | null;
};

/**
 * TilesDimension to choose a product variation on Detailview
 * */
export const TilesDimension = ({
  dimensionData,
  onChange,
  selectedVariation,
}: TilesDimensionProps) => {
  const dimension = getFragmentData(TilesValuesFragmentFragmentDoc, dimensionData);
  const isBot = useIsBot();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  return (
    <Box sx={{ mt: 0.5 }}>
      <Stack
        direction="row"
        useFlexGap
        flexWrap="wrap"
        gap={1.5}
        sx={{ mt: 0.75, button: { position: 'relative' } }}
      >
        {dimension.values.map(({ sku, displayText, availability, isSelected }) => {
          if (!sku) {
            return null;
          }

          return (
            <TileButton
              key={`tilebtn${sku}`}
              sku={sku}
              active={!!isSelected}
              sx={getStyle(getIsAvailable(availability), !!isSelected)}
              size="medium"
              onClick={onChange}
              LinkComponent={Link}
              href={isBot ? getVariationFormattedLink(sku, searchParams, pathname) : undefined}
              selectedVariation={selectedVariation}
            >
              {displayText}
            </TileButton>
          );
        })}
      </Stack>
    </Box>
  );
};
