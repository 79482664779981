import DOMPurify from 'isomorphic-dompurify';
import { Box } from '@packages/shared';
import config from '@packages/config';
import { registerComponents } from '../../ComponentRecognizer/componentRegistry';
import { ComponentTypes } from '../../../../interfaces';

import type { FooterBlockListType } from '../types';

type BlockListProps = {
  data: FooterBlockListType;
};

export enum BlockListTestIds {
  main = 'block-list',
}

export const BlockList = ({ data }: BlockListProps) => {
  const isDarkStyle = config.styleVariant === 'dark';
  const listIcon = isDarkStyle ? '\\2192' : '\\2713';

  return (
    <Box
      sx={{
        '& ul, & ol': {
          m: 0,
        },
        '& ul': {
          paddingLeft: 0,
          listStyleType: 'none',
          '& li': {
            mb: 1,
            '&.type-1': {
              display: 'flex',
              ml: 0,
              /*
               * From the Drupal JSON comes an HTML paragraph containing HTML elements with assigned CSS classes.
               * Graphical hooks are then to be displayed on these elements with certain CSS classes.
               * */
              '&::before': {
                content: `"${listIcon}"`,
                ...(isDarkStyle
                  ? {
                      mr: 2,
                      fontSize: '1.5rem',
                    }
                  : {
                      fontWeight: 'bold',
                    }),
                flexShrink: 0,
                flexBasis: '1rem',
                display: 'inline-flex',
                width: ['1.25rem', '1rem'],
                height: ['0.94rem', '0.8rem'],
              },
            },
            '&.type-3': {
              pl: [2.5, 2],
            },
          },
        },
      }}
      data-testid={BlockListTestIds.main}
      key={`${BlockListTestIds.main}-${data.id}`}
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.field_html_editor) }}
    />
  );
};

// Needed to run this component in Storybook and Jest tests. Register the component in a global componentRegistry object
registerComponents({ [ComponentTypes.BlockList]: BlockList });
