/**
 * converts a base64-encoded string into a readable string,
 *
 * @param input The input string to be decoded from base64
 * @returns Base64-decoded string
 */
export const decodeFromBase64 = (base64: string) => {
  const binString = atob(base64);
  return new TextDecoder().decode(Uint8Array.from(binString, (m) => m.codePointAt(0) as number));
};
