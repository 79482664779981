'use client';

import { type FC } from 'react';
import { DefaultHeader } from './DefaultHeader';
import { MinimalHeader } from './MinimalHeader';
import { PaceId } from './PaceId';
import { useCopiedDynamicYieldCookie } from './useCopiedDynamicYieldCookie';

export type HeaderProps = {
  isMinimal?: boolean;
};

export const Header: FC<HeaderProps> = ({ isMinimal }) => {
  // useAbTest('2024_04_SoFiOn'); // TODO re-add after/during SEARCH-2926, disabled for now because the test was postponed due to missing functionality on the DV

  useCopiedDynamicYieldCookie();

  return (
    <>
      <PaceId />
      {isMinimal ? <MinimalHeader /> : <DefaultHeader />}
    </>
  );
};
