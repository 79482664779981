import { styled } from '@mui/material/styles';
import { Tooltip } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import type { TooltipProps } from '@mui/material/Tooltip';
import { forwardRef } from 'react';
import { cmsFontToTheme } from '@packages/shared/src/utils/cmsFontToTheme';
import type { TooltipVariant } from './types';

/**
 * HtmlTooltip component - A customized version of the Material-UI Tooltip component with enhanced styling and functionality.
 * Doc: https://mui.com/material-ui/react-tooltip/#accessibility
 *
 * @param {TooltipProps} props - The properties for the HtmlTooltip. These are the same properties as the Material-UI Tooltip, with additional styling applied.
 * @param {React.Ref<HTMLDivElement>} ref - A ref that points to the DOM element of the tooltip.
 * @returns {React.ReactElement} A React element that renders a tooltip with custom styles and an arrow.
 */
const HtmlTooltip = styled(
  forwardRef((props: TooltipProps & TooltipVariant, ref) => (
    <Tooltip
      className="tooltip-html"
      ref={ref}
      arrow
      {...props}
      classes={{ popper: props.className }}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: props.variant === 'inline' ? [0, 0] : [0, -14],
              },
            },
          ],
        },
      }}
    />
  )),
)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    '&:before': {
      border: '1px solid rgba(0,0,0, 0.1)',
    },
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: '0 2px 6px 2px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3)',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.dark,
    minWidth: 200,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 'initial',
    margin: 0,
    padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
    '& h1, & h2, & h3, & h4, & h5, & h6, & ul, & ol, & li, & p': {
      margin: 0,
      padding: 0,
      paddingBottom: 1,
      typography: 'body2',
    },
    '& p, & ul li, & ol li': {
      '&:last-of-type': {
        paddingBottom: 0,
      },
    },
    '& ul, & ol': {
      margin: '0 0 0 1rem',
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
    a: {
      color: theme.palette.text.dark,
      '&:hover': {
        color: theme.palette.text.dark,
      },
    },
    '&': cmsFontToTheme,
  },
}));

export { TooltipProps, HtmlTooltip };
