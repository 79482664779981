import type { GetServerSidePropsContext } from 'next';
import type { ParsedUrlQuery } from 'querystring';
import type { DestroyCookie, SetCookie } from '@packages/utilities';

export const cookiePrefix = 'mvt_';
const participantSuffix = '_participant';

export const getTestCookieName = (testId: string) => `${cookiePrefix}${testId}`;
export const getParticipantCookieName = (testId: string) =>
  `${getTestCookieName(testId)}${participantSuffix}`;

type SetTestCookiesParameters = {
  context?: GetServerSidePropsContext<ParsedUrlQuery>;
  testId: string;
  cookies: Record<string, string>;
  setCookie: SetCookie;
  value: {
    participant: string;
    variant: Record<string, string>;
  };
};

export const setTestCookies = ({
  context,
  testId,
  cookies,
  setCookie,
  value,
}: SetTestCookiesParameters) => {
  // expiry date once for in a day and once in 30 days
  const now = new Date().valueOf();

  // NOTE not really a day, but 24h, which will result in +-1 hour in local time when crossing DST boundaries, which hopefully should not be an issue
  // Using the native Date.setDate is OS-timezone-dependent
  // dayjs.add fails to account for DST boundaries (see https://github.com/iamkun/dayjs/issues/1271)
  // To simplify this, we just work fully in UTC
  const day = 24 * 60 * 60 * 1000;

  const testCookieName = getTestCookieName(testId);

  setCookie(context, testCookieName, JSON.stringify(value), {
    expires: new Date(now + 1 * day),
    path: '/',
  });

  const participantCookieName = getParticipantCookieName(testId);

  // set or reset participantID
  if (cookies[participantCookieName] !== value.participant) {
    setCookie(context, participantCookieName, value.participant, {
      expires: new Date(now + 90 * day),
      path: '/',
    });
  }
};

type RemoveTestCookieParameters = {
  context?: GetServerSidePropsContext<ParsedUrlQuery>;
  testId: string;
  removeCookie: DestroyCookie;
};

export const removeTestCookies = ({
  context,
  testId,
  removeCookie,
}: RemoveTestCookieParameters) => {
  removeCookie(context, getParticipantCookieName(testId), {
    path: '/',
  });
  removeCookie(context, getTestCookieName(testId), {
    path: '/',
  });
};

export const getAllTestParticipations = (
  cookies: Record<string, string>,
): Array<{
  testId: string;
  participantId: string;
  variant?: Record<string, string>;
}> => {
  const testIds = Object.keys(cookies).reduce((accumulator, key) => {
    if (!key.startsWith(cookiePrefix)) return accumulator;

    const testId = key.replace(cookiePrefix, '').replace(participantSuffix, '');

    if (!accumulator.includes(testId)) {
      accumulator.push(testId);
    }

    return accumulator;
  }, [] as string[]);

  return testIds.map((testId) => {
    const fullCookieKey = `${cookiePrefix}${testId}`;

    if (cookies[fullCookieKey]) {
      const { participant, variant } = JSON.parse(cookies[fullCookieKey]) as {
        participant: string;
        variant: Record<string, string>;
      };

      return { testId, participantId: participant, variant };
    }

    const participantCookieKey = getParticipantCookieName(testId);

    return {
      testId,
      participantId: cookies[participantCookieKey],
    };
  });
};

export const isAbTestParticipant = ({
  testCookie,
  customTestRecognizerFunction,
}: {
  testCookie: string;
  customTestRecognizerFunction?: () => boolean;
}) => {
  if (testCookie) {
    return !!testCookie;
  }
  if (customTestRecognizerFunction) {
    return customTestRecognizerFunction();
  }
  return false;
};
