import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import useSWR from 'swr';
import { Grid } from '@mui/material';
import { DynamicYield, useMedia } from '@packages/shared';
import { useConfig } from '@packages/utilities';
import { GlycerinSuggestionType } from '@packages/tracking';
import { SuggestionArticleItem } from './SuggestionItem';
import type { SuggestionResult, Suggestion, Result } from '../types';
import { useItemSelection } from '../useItemSelection';
import { SuggestionContext } from './SuggestionContext';
import { SuggestionsSection } from './SuggestionsSection';
import { createApiUrl, headerDefaultFetcher } from '../../../api';

interface SuggestionsProps {
  /** Selected suggest keyboard item */
  cursor: number;
  /** Input from searchfield */
  searchValue: string;
  /** Update input value */
  setValue: (value: string, displayOnly?: boolean) => void;
  /** Callback that is called when the user clicks the suggested item */
  onAccept: (
    selectedSuggestion?: Suggestion,
    allSuggestions?: Result[],
    suggestionType?: GlycerinSuggestionType,
  ) => void;
  /** 
   * Token for fetching data (will be removed when swr completely implements suspense support)
   @deprecated 
  */
  jwtToken: string;
}

// TODO: check if function should be shared
const turnParamsIntoQuery = (params: Record<string, any>) =>
  Object.keys(params)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');

/** SearchSuggestions shows searchresult, category and article suggestions for the typed search text */
export const SearchSuggestions = ({
  searchValue,
  setValue,
  onAccept,
  cursor,
  jwtToken,
}: SuggestionsProps) => {
  const channel = useMedia({ mobile: 'mob', tablet: 'mob', desktop: 'web' });
  const { locale } = useIntl();
  const {
    apiEndpoints: { suggest },
    clientId,
    dynamicYieldEnabled,
  } = useConfig();

  const search = turnParamsIntoQuery({
    query: searchValue,
    locale: locale.replace('-', '_'),
    clientId,
    channel,
  });

  const { data } = useSWR<SuggestionResult>(
    [createApiUrl(`${suggest}?${search}`), jwtToken],
    headerDefaultFetcher,
    {
      suspense: true,
    },
  );

  // TODO: remove non-null assertion after swr adapts its types to "suspense pattern"
  const allSuggestions = useMemo(
    () =>
      data!.suggestresult.result.reduce(
        (val, { suggestCategoryResult: { suggests } }) => [...val, ...suggests],
        [] as Array<Suggestion>,
      ),
    [data],
  );

  const [selectedItem] = useItemSelection(cursor, allSuggestions);

  useEffect(() => {
    if (selectedItem) {
      setValue(selectedItem.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  // TODO: remove non-null assertion after swr adapts its types to "suspense pattern"
  const [searchresults, categories, articles] = data!.suggestresult.result;

  const hasSearchResults = searchresults && searchresults.suggestCategoryResult.suggests.length > 0;
  const hasCategories = categories && categories.suggestCategoryResult.suggests.length > 0;
  const hasArticles = articles && articles.suggestCategoryResult.suggests.length > 0;

  const columns = {
    xs: 1,
    lg: hasArticles && (hasSearchResults || hasCategories) ? 2 : 1,
  };

  const hasAnySuggestions = hasSearchResults || hasCategories || hasArticles;

  return (
    <SuggestionContext.Provider value={selectedItem}>
      {hasAnySuggestions && (
        <Grid
          container
          sx={{ overflowY: ['scroll', 'auto', 'auto'], maxHeight: '50vh' }}
          columns={columns}
        >
          {(hasSearchResults || hasCategories) && (
            <Grid item xs={1}>
              {hasSearchResults && (
                <SuggestionsSection
                  suggestions={searchresults.suggestCategoryResult}
                  onSuggestionItemClick={(suggestion) => {
                    setValue(suggestion.value, false);
                    onAccept?.(suggestion, data!.suggestresult.result, GlycerinSuggestionType.TERM);
                  }}
                />
              )}
              {hasCategories && (
                <SuggestionsSection
                  sx={{ mt: hasSearchResults ? 2 : 0 }}
                  suggestions={categories.suggestCategoryResult}
                  onSuggestionItemClick={(suggestion) => {
                    setValue('', false);
                    onAccept?.(
                      suggestion,
                      data!.suggestresult.result,
                      GlycerinSuggestionType.CATEGORY,
                    );
                  }}
                />
              )}
            </Grid>
          )}
          {hasArticles && (
            <Grid
              item
              xs={1}
              sx={{
                borderLeftColor: ['grey.main'],
                borderLeftWidth: { lg: columns.lg === 2 ? [0, 1] : 0 },
                borderLeftStyle: 'solid',
              }}
            >
              <SuggestionsSection
                sx={{ mt: searchresults || categories ? [2, 2, 0] : 0 }}
                suggestions={articles.suggestCategoryResult}
                SuggestComponent={SuggestionArticleItem}
                onSuggestionItemClick={(suggestion) => {
                  setValue('', false);
                  onAccept?.(
                    suggestion,
                    data!.suggestresult.result,
                    GlycerinSuggestionType.PRODUCT,
                  );
                }}
              />
            </Grid>
          )}
        </Grid>
      )}
      {dynamicYieldEnabled && <DynamicYield dataDyId="serp_SuggestDesktop" />}
    </SuggestionContext.Provider>
  );
};
