import type { Theme } from '@mui/system';

/**
 * Styling for each usable font to set the correct stylings from Theme.
 *
 * In Drupal, fonts are set by setting a class name. Here, each class name is
 * styled with the corresponding styles from the Theme.
 *
 * Usage:
 * sx={{ '&': cmsFontToTheme, }}
 *
 */
export const cmsFontToTheme = (theme: Theme) => ({
  /** GothamWebBook (same as default) (BAUR) */
  // '.GothamSSm3r': {},

  /** GothamWebMedium (BAUR) */
  '.GothamSSm4r, .MontserratSemibold': {
    // TODO: check if cast to any is still required after mui v6 has been released (see links below for more information)
    // https://github.com/mui/material-ui/issues/30678
    // https://github.com/mui/material-ui/discussions/30988
    fontWeight: (theme.typography as any).fontWeightSemiBold,
  },

  /** GothamWebBold (BAUR) */
  '.GothamSSm5r, .MontserratBold': {
    fontWeight: (theme.typography as any).fontWeightBold,
  },

  /** GothamWebBlack (BAUR) */
  '.GothamSSm7r, .MontserratExtraBold': {
    fontWeight: (theme.typography as any).fontWeightBlack,
  },

  /** GothamWebNarrow (BAUR) */
  '.GothamNarrow, .MontserratItalic': {
    fontWeight: (theme.typography as any).fontWeightLight,
    fontStyle: 'italic',
  },

  /** OpenSans  (same as default) (IAW) */
  // '.OpenSans': {},

  /** OpenSansBold (IAW) */
  '.OpenSansBold': {
    fontWeight: (theme.typography as any).fontWeightBold,
  },

  /** OttoSans (same as default) (OVAT) */
  // '.OttoSans': {},

  /** OttoSansBold (OVAT) */
  '.OttoSansBold': {
    fontWeight: (theme.typography as any).fontWeightBold,
  },

  /** OttoSansThin (OVAT) */
  '.OttoSansThin': {
    fontWeight: (theme.typography as any).fontWeightLight,
  },

  /** KalamBold (OVAT) */
  '.KalamBold': {
    // hardcoded hash from next/font implementation
    fontFamily: '__secondary_136b37',
    fontWeight: (theme.typography as any).fontWeightBold,
  },

  /** PantonBold (ACH) */
  '.PantonBold': {
    fontWeight: (theme.typography as any).fontWeightBold,
  },

  /** PantonBlack  (ACH) */
  '.PantonBlack': {
    fontWeight: (theme.typography as any).fontWeightBlack,
  },

  /** PoppinsRegular (same as default) (UVS) */
  // '.PoppinsRegular': {},

  /** PoppinsLIght (UVS) */
  '.PoppinsLight': {
    fontWeight: (theme.typography as any).fontWeightLight,
  },

  /** PoppinsBold (UVS) */
  '.PoppinsBold': {
    fontWeight: (theme.typography as any).fontWeightBold,
  },

  /** PoppinsMedium (UVS) */
  '.PoppinsMedium': {
    fontWeight: (theme.typography as any).fontWeightMedium,
  },

  /** PoppinsSemibold (UVS) */
  '.PoppinsSemibold': {
    fontWeight: (theme.typography as any).fontWeightSemiBold,
  },

  /** new tenant generic font setup */
  '.font-normal-300': {
    fontWeight: (theme.typography as any).fontWeightLight,
  },
  '.font-normal-600': {
    fontWeight: (theme.typography as any).fontWeightSemiBold,
  },
  '.font-normal-700': {
    fontWeight: (theme.typography as any).fontWeightBold,
  },
  '.font-normal-700-highlighted': {
    fontFamily: 'Kalam',
    fontWeight: (theme.typography as any).fontWeightBold,
  },
  '.font-normal-800': {
    fontWeight: (theme.typography as any).fontWeightBlack,
  },
  '.font-italic-400': {
    fontWeight: (theme.typography as any).fontWeightLight,
    fontStyle: 'italic',
  },
});
